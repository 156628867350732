/* 05fd4470e2ac4673d04bea858519bd1f18216052
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalizeInvoicesMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  invoice_id: Types.Scalars['uuid'];
}>;


export type FinalizeInvoicesMutation = { __typename?: 'Mutation', mri_finalize_invoices_for_customer: { __typename?: 'Customer', id: string } };


export const FinalizeInvoicesDocument = gql`
    mutation FinalizeInvoices($customer_id: uuid!, $invoice_id: uuid!) {
  mri_finalize_invoices_for_customer(
    customer_id: $customer_id
    invoices_to_finalize_in_order: [$invoice_id]
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type FinalizeInvoicesMutationFn = Apollo.MutationFunction<FinalizeInvoicesMutation, FinalizeInvoicesMutationVariables>;

/**
 * __useFinalizeInvoicesMutation__
 *
 * To run a mutation, you first call `useFinalizeInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeInvoicesMutation, { data, loading, error }] = useFinalizeInvoicesMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useFinalizeInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeInvoicesMutation, FinalizeInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeInvoicesMutation, FinalizeInvoicesMutationVariables>(FinalizeInvoicesDocument, options);
      }
export type FinalizeInvoicesMutationHookResult = ReturnType<typeof useFinalizeInvoicesMutation>;
export type FinalizeInvoicesMutationResult = Apollo.MutationResult<FinalizeInvoicesMutation>;
export type FinalizeInvoicesMutationOptions = Apollo.BaseMutationOptions<FinalizeInvoicesMutation, FinalizeInvoicesMutationVariables>;