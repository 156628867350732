type ValidKey = string | number | symbol;

export function isObj(v: unknown): v is Record<ValidKey, unknown> {
  return typeof v === "object" && v !== null;
}

/**
 * If `v` is an object, get the value at the given key(s). If `v` is not
 * an object, returns undefined.
 */
export function get(
  v: unknown,
  key: ValidKey,
  ...nextKeys: ValidKey[]
): unknown {
  const val = isObj(v) ? v[key] : undefined;
  const next = nextKeys.shift();
  return next === undefined ? val : get(val, next, ...nextKeys);
}
