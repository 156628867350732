import React, { useEffect } from "react";
import classnames from "classnames";
import {
  useTable,
  useSortBy,
  Row as RTRow,
  Column as RTColumn,
  usePagination,
  RowPropGetter as RTRowPropGetter,
  useExpanded,
  useGroupBy,
  useFlexLayout,
  PluginHook,
  CellProps,
} from "react-table";

import { InternalLink } from "components/Typography";
type Accessor<R, Type = string | number> = (row: R) => Type;

// The SortingRule type exposed from react-table unhelpfully
// types the id field as `StringKey<T> | string`, which can be
// simplified to just `string`.
export interface SortRule {
  id: string;
  desc?: boolean;
}

export interface AlignOptions<T> {
  header: "left" | "right";
  row: (row: T) => "left" | "right";
}

import styles from "./index.module.less";
import { Icon, Subtitle, Caption } from "design-system";

import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";

import { TableSkeleton } from "./skeleton";
import { BasicPagination, BasicPaginationProps } from "./BasicPagination";

export { TableSkeleton };
export * from "./BasicPagination";

export type Column<T> = {
  render: (row: T) => React.ReactElement | string | null;
  id: string;
  header: string;
  align?: "left" | "right" | AlignOptions<T>;
  width?: number;
  cellClassName?: string | ((row?: T) => string | undefined);
  headerClassName?: string;
  textWrappable?: boolean;
  disabled?: boolean;
} & (
  | {
      sortable: true;
      sortDescendingFirst?: boolean;
      comparator: (a: T, b: T) => number;
    }
  | {
      sortable?: false;
    }
);

export type RowTheme =
  | "enabled"
  | "disabled"
  | "expanded"
  | "highlighted"
  | "error"
  | "warning";

export type TableProps<T extends object> = {
  loading?: boolean;
  data: T[];
  columns: Column<T>[];
  maxPageSize?: number;
  defaultSortBy?: SortRule[];
  skeletonRows?: number;
  getRowTheme?: (row: T) => RowTheme;
  rowRoutePath?: (row: T) => string | null | undefined;
  onRowClick?: (row: T) => void;
  isRowClickable?: (row: T) => boolean;
  nestedRows?: "expandable" | "visible";
  emptyState?: React.ReactNode;
  flexLayout?: boolean;
  onSortChanged?: (newSort: SortRule[]) => void;
  manualPagination?: {
    onPageChanged: (newPageState: {
      newPageIndex: number;
      newPageSize: number;
    }) => void;
    pageCount: number;
    numItems: number;
  };
  pageIndex?: number;
  noPageReset?: boolean;
  noBottomBorder?: boolean;
  containerClassName?: string;
  theadClassName?: string;
  maxItems?: number;
  basicPagination?: BasicPaginationProps;
};

const DEFAULT_PAGE_SIZE = 10;

export function Table<T extends object>(props: TableProps<T>) {
  const maxPageSize = props.maxPageSize || DEFAULT_PAGE_SIZE;
  const defaultSortBy = props.defaultSortBy ?? [];
  const expandable = props.nestedRows === "expandable";

  const routePaths = new Map<T, string | null>();
  const getRoutePath = (row: T) => {
    const cached = routePaths.get(row);
    if (cached !== undefined) {
      return cached;
    }

    const routePath = props.rowRoutePath?.(row) ?? null;
    routePaths.set(row, routePath);
    return routePath;
  };

  const getRowProps: RTRowPropGetter<T> = (row, meta) => {
    const rowIsClickable =
      (!!getRoutePath(meta.row.original) ||
        !!props.onRowClick ||
        (expandable && meta.row.canExpand)) &&
      props.isRowClickable
        ? props.isRowClickable(meta.row.original)
        : true;
    const rowStyles = [];
    if (props.getRowTheme) {
      rowStyles.push(props.getRowTheme(meta.row.original));
    }
    if (meta.row.depth >= 1 && props.nestedRows !== undefined) {
      rowStyles.push("expanded");
    }
    const className = classnames(
      rowStyles.map((r) => styles[r]),
      {
        [styles.clickable]: rowIsClickable,
      },
    );
    return {
      ...row,
      className,
      onClick: () =>
        (expandable && meta.row.toggleRowExpanded()) ||
        (props.onRowClick &&
          (props.isRowClickable
            ? props.isRowClickable(meta.row.original)
            : true) &&
          props.onRowClick(meta.row.original)),
    };
  };

  const columnIDToOriginal = React.useMemo(
    () => Object.fromEntries(props.columns.map((c) => [c.id, c])),
    [props.columns],
  );

  const columns = React.useMemo(() => {
    const computedColumns: RTColumn<T>[] = props.columns.flatMap((c) =>
      c.disabled
        ? []
        : {
            Header: c.header,
            id: c.id,
            accessor: c.id as any,
            Cell: (props: CellProps<T, any>) => {
              const rendered = c.render(props.row.original);
              return typeof rendered === "string" ? <>{rendered}</> : rendered;
            },
            disableSortBy: !c.sortable,
            sortDescFirst: c.sortable && c.sortDescendingFirst,
            sortType: (a: RTRow<T>, b: RTRow<T>) => {
              if (!c.sortable) {
                return 0;
              }
              return c.comparator(a.original, b.original);
            },
            width: c.width ?? 150,
          },
    );
    if (expandable) {
      computedColumns.unshift({
        id: "expander",
        Header: "",
        Cell: (props: CellProps<T>) =>
          props.row.depth < 1 ? (
            props.row.canExpand ? (
              props.row.isExpanded ? (
                <Icon
                  icon="chevronDownOutline"
                  className={styles.expandableIcon}
                />
              ) : (
                <Icon
                  icon="chevronForwardOutline"
                  className={styles.expandableIcon}
                />
              )
            ) : (
              <Icon
                icon="chevronForwardOutline"
                className={styles.unexpandableIcon}
              />
            )
          ) : null,
        disableSortBy: true,
        sortDescFirst: false,
        sortType: () => 0,
        minWidth: 40,
      });
    }
    return computedColumns;
  }, [props.columns, expandable]);

  const data = React.useMemo(() => {
    return props.nestedRows === "visible"
      ? props.data.map((r) => ({ ...r, expanded: true }))
      : props.data;
  }, [props.data, props.nestedRows]);

  const plugins: Array<PluginHook<T>> = [
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  ];
  if (props.flexLayout) {
    plugins.push(useFlexLayout);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    state,
  } = useTable<T>(
    {
      columns,
      data: data,
      initialState: {
        pageSize: maxPageSize,
        pageIndex: props.pageIndex ?? 0,
        sortBy: defaultSortBy,
      },
      // We have to avoid passing in a pageCount (even with an undefined
      // value!) unless we're doing manual pagination for... reasons? But this
      // actually fixes a bug in viewing credit ledgers, so I'm keeping it.
      //
      // Dearest future dev, if you want to change this, the thing to test is
      // paging back and forth between two pages in the credit (grants) ledger
      // for a customer. With the buggy behavior, only every OTHER click will
      // actually change the table contents. See
      // https://github.com/tannerlinsley/react-table/issues/2321#issuecomment-647850730
      ...(props.manualPagination
        ? { pageCount: props.manualPagination.pageCount }
        : {}),
      manualSortBy: !!props.onSortChanged,
      manualPagination: !!props.manualPagination || !!props.basicPagination,
      autoResetPage: !props.noPageReset,
    },
    ...plugins,
  );

  if (
    !props.loading &&
    state.pageIndex > 0 &&
    state.pageIndex >= pageOptions.length
  ) {
    gotoPage(state.pageIndex - 1);
  }

  // Fire the page change callback and update internal state.
  const changePage = (newPageIndex: number) => {
    if (props.manualPagination?.onPageChanged) {
      props.manualPagination.onPageChanged({
        newPageIndex: newPageIndex,
        newPageSize: state.pageSize,
      });
    }
    gotoPage(newPageIndex);
  };
  const nextPage = () => changePage(state.pageIndex + 1);
  const previousPage = () => changePage(state.pageIndex - 1);

  useEffect(() => {
    if (props.onSortChanged) {
      props.onSortChanged(state.sortBy);
    }
  }, [props.onSortChanged, state.sortBy]);

  if (props.loading) {
    return (
      <TableSkeleton
        noBottomBorder={props.noBottomBorder}
        columns={props.columns.filter((c) => !c.disabled)}
        numRows={props.skeletonRows}
        containerClassName={props.containerClassName}
        basicPagination={props.basicPagination}
      />
    );
  }

  return (
    <div
      className={classnames(props.containerClassName, styles.tableContainer)}
    >
      <table
        className={classnames(styles.table, {
          [styles.expandable]: expandable,
        })}
        {...getTableProps({ style: { minWidth: undefined } })}
      >
        <thead className={props.theadClassName}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th />
              {headerGroup.headers.map((column) => {
                const headerProps = column.getHeaderProps(
                  column.getSortByToggleProps(),
                );
                const originalColumn = columnIDToOriginal[column.id];
                const alignmentSetting =
                  originalColumn.align === "left" ||
                  originalColumn.align === "right"
                    ? originalColumn.align
                    : originalColumn.align?.header ?? "left";
                headerProps.style = {
                  ...headerProps.style,
                  textAlign: alignmentSetting,
                };

                return (
                  <th
                    {...headerProps}
                    className={classnames(
                      headerProps.className,
                      typeof originalColumn.cellClassName === "function"
                        ? originalColumn.cellClassName()
                        : originalColumn.cellClassName,
                    )}
                  >
                    <div
                      className={classnames(
                        styles.header,
                        styles[alignmentSetting],
                        originalColumn.headerClassName,
                      )}
                    >
                      {column.canSort && (
                        <SortingIndicator
                          direction={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : "none"
                          }
                        />
                      )}
                      <span>{column.render("Header")}</span>
                    </div>
                  </th>
                );
              })}
              <th />
            </tr>
          ))}
        </thead>

        <tbody
          {...getTableBodyProps()}
          className={classnames({
            [styles.noBottomBorder]: props.noBottomBorder,
          })}
        >
          {page.map((row) => {
            prepareRow(row);
            const routePath = getRoutePath(row.original);

            return (
              <tr {...row.getRowProps(getRowProps)}>
                <td />
                {row.cells.map((cell, i) => {
                  const cellProps = cell.getCellProps();
                  const originalColumn = columnIDToOriginal[cell.column.id];
                  const alignmentSetting =
                    originalColumn.align === "left" ||
                    originalColumn.align === "right"
                      ? originalColumn.align
                      : originalColumn.align?.row(row.original) ?? "left";
                  cellProps.style = {
                    ...cellProps.style,
                    textAlign: alignmentSetting,
                  };
                  return (
                    <td
                      {...cellProps}
                      className={classnames(
                        styles[alignmentSetting],
                        typeof originalColumn.cellClassName === "function"
                          ? originalColumn.cellClassName(row.original)
                          : originalColumn.cellClassName,
                        {
                          [styles.cellContainer]: !routePath,
                        },
                        originalColumn.textWrappable ? styles.textWrap : null,
                      )}
                    >
                      {routePath ? (
                        <InternalLink
                          className={styles.cellContainer}
                          routePath={routePath}
                          {...(i === 0 ? {} : { tabIndex: -1 })}
                        >
                          {cell.render("Cell")}
                        </InternalLink>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
                <td />
              </tr>
            );
          })}
        </tbody>
      </table>
      {!props.loading && props.emptyState && page.length === 0
        ? props.emptyState
        : null}
      {(canPreviousPage || canNextPage) && (
        <Pagination
          canPrevPage={canPreviousPage}
          prevPage={previousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          currentPage={state.pageIndex}
          currentPageSize={page.length}
          maxPageSize={state.pageSize}
          numPages={pageOptions.length}
          numItems={props.manualPagination?.numItems ?? data.length}
          goToPage={changePage}
          pageOptions={pageOptions}
          maxItems={props.maxItems}
        />
      )}
      {props.basicPagination && <BasicPagination {...props.basicPagination} />}
    </div>
  );
}

function clampSortValue(v: number) {
  if (v > 0) {
    return 1;
  } else if (v === 0) {
    return 0;
  } else {
    return -1;
  }
}

export const SortFunctions = {
  String:
    <T,>(accessor: Accessor<T, string>) =>
    (a: T, b: T) =>
      clampSortValue(accessor(b).localeCompare(accessor(a))),
  Number:
    <T,>(accessor: Accessor<T, number>) =>
    (a: T, b: T) =>
      clampSortValue(accessor(a) - accessor(b)),
  Date:
    <T,>(accessor: Accessor<T, Date | undefined>) =>
    (a: T, b: T) =>
      clampSortValue(
        (accessor(a)?.valueOf() || 0) - (accessor(b)?.valueOf() || 0),
      ),
};

interface SortingIndicatorProps {
  direction: "asc" | "desc" | "none";
}

const SortingIndicator: React.FC<SortingIndicatorProps> = ({ direction }) => {
  const upClassname = classnames({
    [styles.active]: direction === "asc",
  });
  const downClassname = classnames({
    [styles.active]: direction === "desc",
  });
  return (
    <div className={styles.sortingIndicator}>
      <Icon icon="caretUp" className={upClassname} />
      <Icon icon="caretDown" className={downClassname} />
    </div>
  );
};

interface PaginationButtonProps {
  onClick: () => void;
  selected: boolean;
  displayPage: number;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
  selected,
  onClick,
  displayPage,
}) => (
  <Button
    onClick={onClick}
    className={styles.button}
    text={String(displayPage)}
    theme={selected ? "primary" : "secondary"}
    size="sm"
  />
);

interface PaginationProps {
  canPrevPage: boolean;
  prevPage: () => void;
  canNextPage: boolean;
  nextPage: () => void | undefined;
  currentPage: number;
  currentPageSize: number;
  maxPageSize: number;
  numPages: number;
  numItems: number;
  goToPage: (page: number) => void;
  pageOptions: number[];
  maxItems?: number;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  let pages = new Set<number>();
  if (props.numPages <= 4) {
    pages = new Set(Array(props.numPages).keys());
  } else {
    pages.add(0);
    pages.add(props.numPages - 1);
    pages.add(
      props.currentPage === 0 ? props.currentPage + 2 : props.currentPage - 1,
    );
    pages.add(
      props.currentPage === props.numPages - 1
        ? props.currentPage - 2
        : props.currentPage + 1,
    );
    pages.add(props.currentPage);
  }

  return (
    <div className={styles.pagination}>
      <div className={styles.pages}>
        {props.maxItems && props.maxItems >= props.numItems ? (
          <Subtitle level={4} className="pr-24 leading-3">
            Only the first {props.maxItems} results are shown
          </Subtitle>
        ) : null}
        <Caption className={styles.pageItems}>{`${
          props.currentPage * props.maxPageSize + 1
        } - ${
          props.currentPage * props.maxPageSize + props.currentPageSize
        } of ${props.numItems}`}</Caption>
        <IconButton
          onClick={props.prevPage}
          className={styles.button}
          disabled={!props.canPrevPage}
          theme="secondary"
          icon="chevronLeft"
          size="sm"
        />
        {props.pageOptions
          .reduce<(JSX.Element | null)[]>((prev, curr) => {
            if (pages.has(curr)) {
              prev.push(
                <PaginationButton
                  key={`button ${curr}`}
                  onClick={() => props.goToPage(curr)}
                  selected={curr === props.currentPage}
                  displayPage={curr + 1}
                />,
              );
            } else if (prev[prev.length - 1] !== null) {
              prev.push(null);
            }
            return prev;
          }, [])
          .map(
            (v, i) =>
              v ?? (
                <div className={styles.ellipsis} key={`ellipsis ${i}`}>
                  ...
                </div>
              ),
          )}
        <IconButton
          onClick={props.nextPage}
          className={styles.button}
          disabled={!props.canNextPage}
          theme="secondary"
          icon="chevronRight"
          size="sm"
        />
      </div>
    </div>
  );
};
