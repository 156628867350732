import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { FormController } from "lib/FormController";
import React, { useMemo } from "react";

import { Body, Input, Select, Toggle } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Icon } from "tenaissance/components/Icon";

import { useNow } from "lib/date";
import { ProductListItem } from "../../lib/ProductListItem";
import {
  useCreateCompositeProductMutation,
  useCreateFixedProductMutation,
  useCreateUsageProductMutation,
  useCreateSubscriptionProductMutation,
  useListBillableMetricsQuery,
  useProductsQuery,
  useCreateProServiceProductMutation,
} from "./data.graphql";
import { Schema } from "../Schema";
import { useFeatureFlag } from "lib/launchdarkly";
import Fuse from "fuse.js";
import {
  ConversionOperation,
  RoundingMethod,
} from "types/generated-graphql/__types__";
import { QuantityConversion } from "./QuantityConversion";
import { QuantityRounding } from "./QuantityRounding";
import { Link } from "react-router-dom";
import { components, type MenuProps } from "react-select";
import { type Option } from "design-system";

type LinkOptions = {
  url: string;
  label: string | JSX.Element;
};

/** CustomMenu will allow devs to prepend a clickable link to the top of a Select, ie BillableMetrics dropdown */
export const CustomMenu = ({
  menuProps,
  linkOptions,
}: {
  menuProps: MenuProps<Option>;
  linkOptions?: LinkOptions;
}) => {
  return (
    <components.Menu {...menuProps}>
      {!!linkOptions && (
        <Link
          className="flex items-center justify-between border-b border-grey-50 px-12 py-8 text-success-700 hover:bg-grey-50"
          to={linkOptions.url}
          relative="path"
          target="_blank"
        >
          {linkOptions.label}
          <Icon icon="share03" size={12} />
        </Link>
      )}
      {menuProps.children}
    </components.Menu>
  );
};

const useCreateProductController = FormController.createHook(
  Schema.CreateProductInput,
  {
    init(props: ProductModalProps) {
      return {
        type: props.enforceType || "usage",
      };
    },
  },
);
type CreateProductController = ReturnType<typeof useCreateProductController>;

interface ProductModalProps {
  onClose: (newProductId?: string) => void;
  enforceType?: "usage" | "fixed" | "composite" | "subscription" | "proService";
}

export const fuseParams = {
  ignoreLocation: true,
  includeScore: true,
  threshold: 0.3,
};

export const CreateProductModal: React.FC<ProductModalProps> = (props) => {
  const { onClose, enforceType } = props;
  const { environmentType } = useEnvironment();
  const now = useNow();
  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const allowProfessionalServices = nonGAContractFeaturesEnabled?.includes(
    "PROFESSIONAL_SERVICES",
  );
  const netsuiteEnabled = nonGAContractFeaturesEnabled?.includes("NETSUITE");
  const refundableProductsEnabled = nonGAContractFeaturesEnabled?.includes(
    "REFUNDABLE_PRODUCTS",
  );
  const excludeFreeUsageEnabled = nonGAContractFeaturesEnabled?.includes(
    "COMPOSITE_EXCLUDE_FREE_USAGE",
  );

  const pushMessage = useSnackbar();
  const pushSuccessMessage = (type: string, productName: string) => {
    pushMessage({
      content: `Successfully created new ${type === "proService" ? "professional service" : type} product: ${productName}`,
      type: "success",
    });
  };
  const pushErrorMessage = (type: string, e: unknown) =>
    pushMessage({
      content: `Failed to create new ${type} product: ${e}`,
      type: "error",
    });

  const [createCompositeProductMutation, createCompositeProductResult] =
    useCreateCompositeProductMutation();
  const [createFixedProductMutation, createFixedProductResult] =
    useCreateFixedProductMutation();
  const [createUsageProductMutation, createUsageProductResult] =
    useCreateUsageProductMutation();
  const [createSubscriptionProductMutation, createSubscriptionProductResult] =
    useCreateSubscriptionProductMutation();
  const [createProServiceProductMutation, createProServiceProductResult] =
    useCreateProServiceProductMutation();

  // Get list of usage products for compositeProductIds selection
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useProductsQuery();
  const allProducts = productsData?.contract_pricing.products ?? [];
  const usageProducts = allProducts.flatMap((p) =>
    p.__typename === "UsageProductListItem" ||
    p.__typename === "SubscriptionProductListItem"
      ? { id: p.id, name: ProductListItem.getName(p, now) }
      : [],
  );
  const tagsFromDb = [
    ...new Set(
      allProducts.flatMap((product) => ProductListItem.getTags(product, now)),
    ),
  ];

  // Get billable metrics for usage product creation
  const {
    data: billableMetricsData,
    loading: billableMetricsLoading,
    error: billableMetricsError,
  } = useListBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  // We currently only support count, sum and max billable metrics
  const countSumAndMaxBillableMetrics = [
    ...(billableMetricsData?.billable_metrics ?? [])
      .filter((bm) => ["count", "sum", "max"].includes(bm.aggregate))
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
  ];

  const ctrl = useCreateProductController(props);
  const productType = ctrl.get("type");
  const isSubmitting =
    createCompositeProductResult.loading ||
    createFixedProductResult.loading ||
    createUsageProductResult.loading;

  const [search, setSearch] = React.useState("");
  const tagFuse = useMemo(() => {
    return new Fuse(tagsFromDb, fuseParams);
  }, [tagsFromDb]);
  const existingTagValues = new Set(
    (search ? tagFuse.search(search).map((r) => r.item) : tagsFromDb).concat(
      ctrl.state.fields["tags"].value ?? [],
    ),
  ); // we need the existing tag values in the options for them to render properly
  const tagOptions = Array.from(existingTagValues);
  if (search && !existingTagValues.has(search)) {
    tagOptions.unshift(search);
  }

  const productTypeOptions: {
    label: string;
    value: "composite" | "fixed" | "usage" | "subscription" | "proService";
    hidden?: boolean | undefined;
  }[] = [
    { label: "Usage", value: "usage" as const },
    { label: "Fixed", value: "fixed" as const },
    { label: "Composite", value: "composite" as const },
    {
      label: "Subscription",
      value: "subscription" as const,
    },
  ];

  if (allowProfessionalServices) {
    productTypeOptions.push({
      label: "Professional service",
      value: "proService" as const,
    });
  }

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    const baseVariables = {
      ...valid,
      name: valid.name.trim(),
      tags: valid.tags,
      netSuiteInternalItemId: valid.netSuiteInternalItemId?.trim(),
    };

    switch (valid.product.type) {
      case "composite": {
        try {
          const result = await createCompositeProductMutation({
            variables: {
              ...baseVariables,
              compositeProductIds: valid.product.compositeProductIds,
              compositeTags: valid.product.compositeTags,
              netSuiteOverageItemId:
                valid.product.netSuiteOverageItemId?.trim(),
              excludeFreeUsage: valid.product.excludeFreeUsage,
            },
            update(cache) {
              cache.evict({ fieldName: "contract_pricing" });
            },
          });
          const newProductId =
            result.data?.create_composite_product_list_item?.id;
          if (newProductId) {
            pushSuccessMessage("composite", valid.name);
          }
          onClose(newProductId);
        } catch (e) {
          pushErrorMessage("composite", e);
        }
        return;
      }
      case "fixed": {
        try {
          const fixedProductResult = await createFixedProductMutation({
            variables: baseVariables,
            update(cache) {
              cache.evict({ fieldName: "contract_pricing" });
            },
          });
          const newProductId =
            fixedProductResult.data?.create_fixed_product_list_item?.id;
          if (newProductId) {
            pushSuccessMessage("fixed", valid.name);
          }
          onClose(newProductId);
        } catch (e) {
          pushErrorMessage("fixed", e);
        }
        return;
      }
      case "usage": {
        try {
          const usageProductResult = await createUsageProductMutation({
            variables: {
              ...baseVariables,
              billableMetricId: valid.product.billableMetricId,
              netSuiteOverageItemId:
                valid.product.netSuiteOverageItemId?.trim(),
              quantityConversion: valid.product.quantityConversion
                ? {
                    conversion_factor:
                      valid.product.quantityConversion.conversionFactor.toString(),
                    name: valid.product.quantityConversion.name,
                    operation:
                      valid.product.quantityConversion.operation === "Divide"
                        ? ConversionOperation.Divide
                        : ConversionOperation.Multiply,
                  }
                : undefined,
              quantityRounding: valid.product.quantityRounding
                ? {
                    decimal_places:
                      valid.product.quantityRounding.decimalPlaces,
                    rounding_method:
                      valid.product.quantityRounding.roundingMethod,
                  }
                : undefined,
            },
            update(cache) {
              cache.evict({ fieldName: "contract_pricing" });
            },
          });
          const newProductId =
            usageProductResult.data?.create_usage_product_list_item?.id;
          if (newProductId) {
            pushSuccessMessage("usage", valid.name);
          }
          onClose(newProductId);
        } catch (e) {
          pushErrorMessage("usage", e);
        }
        return;
      }
      case "subscription":
        try {
          const subscriptionProductResult =
            await createSubscriptionProductMutation({
              variables: {
                ...baseVariables,
                netSuiteOverageItemId:
                  valid.product.netSuiteOverageItemId?.trim(),
              },
              update(cache) {
                cache.evict({ fieldName: "contract_pricing" });
              },
            });
          const newProductId =
            subscriptionProductResult.data
              ?.create_subscription_product_list_item?.id;
          if (newProductId) {
            pushSuccessMessage("subscription", valid.name);
          }
          onClose(newProductId);
        } catch (e) {
          pushErrorMessage("subscription", e);
        }
        return;
      case "proService": {
        try {
          const proServiceProductResult = await createProServiceProductMutation(
            {
              variables: baseVariables,
              update(cache) {
                cache.evict({ fieldName: "contract_pricing" });
              },
            },
          );

          if (
            proServiceProductResult.data?.create_pro_service_product_list_item
              ?.id
          ) {
            pushSuccessMessage("proService", valid.name);
          }
          onClose();
        } catch (e) {
          pushErrorMessage("proService", e);
        }
        return;
      }
      default: {
        valid.product satisfies never;
      }
    }
  });

  if (billableMetricsError) {
    return (
      <ErrorEmptyState
        title="Could not fetch billable metrics"
        error={billableMetricsError}
      />
    );
  }
  if (productsError) {
    return (
      <ErrorEmptyState title="Could not fetch products" error={productsError} />
    );
  }

  return (
    <Popup
      isOpen
      onRequestClose={onClose}
      title="Create a product"
      size="lg"
      className="max-h-screen overflow-visible"
      actions={
        <>
          <Button onClick={() => onClose()} text="Cancel" theme="linkGray" />
          <Button
            onClick={onSubmit}
            disabled={
              !ctrl.appearsValid() ||
              createCompositeProductResult.loading ||
              createFixedProductResult.loading ||
              createUsageProductResult.loading ||
              createSubscriptionProductResult.loading ||
              createProServiceProductResult.loading
            }
            loading={isSubmitting}
            text="Save"
            theme="primary"
            type="submit"
          />
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <input type="submit" className="hidden" />
        <Body level={2}>
          Products are line items that will appear on invoices. Create a new
          product that can then be applied to a rate card or a contract.
        </Body>
        <div className="grid gap-12">
          <Input
            {...ctrl.props.Input("name", {
              name: "Name",
              placeholder: "Enter name as it will appear on an invoice",
            })}
          />
          <Select
            {...ctrl.props.MultiSelect("tags", {
              name: "Tags",
              placeholder: "Add tags",
              loading: productsLoading,
              onSearch: (option) => {
                setSearch(option);
              },
              options: tagOptions.map((tag) => ({ label: tag, value: tag })),
              disabled: productType === "proService",
              tooltip:
                productType === "proService"
                  ? "Tags are not supported for professional service products"
                  : undefined,
            })}
          />
          {netsuiteEnabled && (
            <Input
              {...ctrl.props.Input("netSuiteInternalItemId", {
                name: "NetSuite internal item ID",
                placeholder: "Enter ID",
              })}
            />
          )}
          <Select
            {...ctrl.props.Select("type", {
              name: "Product type",
              placeholder: "Select a product type",
              options: productTypeOptions,
              disabled: enforceType !== undefined,
              menuPosition: "fixed",
              map: (update) => ({
                type: update.type,
                product: {
                  type: update.type,
                },
              }),
            })}
          />

          {(() => {
            switch (productType) {
              case "fixed":
                return <FixedProductFields parent={ctrl} />;
              case "usage":
                return (
                  <UsageProductFields
                    parent={ctrl}
                    billableMetrics={countSumAndMaxBillableMetrics}
                    loading={billableMetricsLoading}
                    options={{ netsuiteEnabled }}
                  />
                );
              case "subscription":
                return (
                  <SubscriptionProductFields
                    parent={ctrl}
                    options={{ netsuiteEnabled }}
                  />
                );
              case "composite":
                return (
                  <CompositeProductFields
                    parent={ctrl}
                    usageProducts={usageProducts}
                    tags={tagsFromDb}
                    loading={productsLoading}
                    options={{
                      netsuiteEnabled,
                      excludeFreeUsageEnabled,
                    }}
                  />
                );
              case "proService":
                return null;
            }
          })()}

          {refundableProductsEnabled && (
            <Toggle
              {...ctrl.props.Toggle("isRefundable", {
                label: "Refundable",
              })}
            />
          )}
        </div>
      </form>
    </Popup>
  );
};

const useFixedController = useCreateProductController.child(
  Schema.FixedProductInput,
  {
    read(parent) {
      const product = parent.get("product");
      return product?.type === "fixed" ? product : {};
    },
    write(self) {
      return { product: self.getUnvalidatedInputs() };
    },
  },
);

// Even if it doesn't have any custom fields, controller initialization is necessary to set initial values (type)
export const FixedProductFields: React.FC<{
  parent: CreateProductController;
  slot?: React.ReactNode;
}> = ({ parent, slot }) => {
  useFixedController(parent);
  return <>{slot}</>;
};

const useCompositeController = useCreateProductController.child(
  Schema.CompositeProductInput,
  {
    read(parent) {
      const product = parent.get("product");
      return product?.type === "composite" ? product : {};
    },
    write(self) {
      return { product: self.getUnvalidatedInputs() };
    },
  },
);
export const CompositeProductFields: React.FC<{
  parent: CreateProductController;
  usageProducts: { name: string; id: string }[];
  tags: string[];
  loading: boolean;
  slot?: React.ReactNode;
  options?: {
    netsuiteEnabled?: boolean;
    excludeFreeUsageEnabled?: boolean;
  };
}> = ({ parent, usageProducts, tags, loading, slot, options }) => {
  const ctrl = useCompositeController(parent);
  return (
    <>
      <Select
        {...ctrl.props.MultiSelect("compositeProductIds", {
          name: "Associated products",
          placeholder: "Search by name or ID",
          loading,
          options: usageProducts.map((p) => ({
            label: p.name,
            value: p.id,
          })),
          tooltip:
            "Composite products can be composed of usage and subscription products only",
        })}
      />
      <Select
        {...ctrl.props.MultiSelect("compositeTags", {
          name: "Associated tags",
          placeholder: "Search by tag",
          loading,
          options: tags.map((p) => ({
            label: p,
            value: p,
          })),
        })}
      />
      {slot}
      {options?.netsuiteEnabled && (
        <Input
          {...ctrl.props.Input("netSuiteOverageItemId", {
            name: "NetSuite overage item ID",
            placeholder: "Enter ID",
          })}
        />
      )}
      {options?.excludeFreeUsageEnabled && (
        <Toggle
          {...ctrl.props.Toggle("excludeFreeUsage", {
            label: "Exclude free usage",
          })}
        />
      )}
    </>
  );
};

const useUsageController = useCreateProductController.child(
  Schema.UsageProductInput,
  {
    read(parent) {
      const product = parent.get("product");
      return product?.type === "usage" ? product : {};
    },
    write(self) {
      return { product: self.getUnvalidatedInputs() };
    },
  },
);

export const UsageProductFields: React.FC<{
  parent: CreateProductController;
  billableMetrics: { name: string; id: string }[];
  loading: boolean;
  slot?: React.ReactNode;
  options?: {
    netsuiteEnabled?: boolean;
  };
}> = ({ parent, billableMetrics, loading, slot, options }) => {
  const ctrl = useUsageController(parent);
  return (
    <>
      <Select
        {...ctrl.props.Select("billableMetricId", {
          name: "Billable metric",
          placeholder: "Search by name or ID",
          loading,
          options: billableMetrics.map((bm) => ({
            label: bm.name,
            value: bm.id,
          })),
          tooltip:
            "Contract usage products must use a count, sum, or max billable metric",
          __internalComponentOverrides: {
            Menu: (menuProps: MenuProps<Option>) => (
              <CustomMenu
                menuProps={menuProps}
                linkOptions={{
                  url: "/billable-metrics/new",
                  label: (
                    <span className="flex items-center">
                      <Icon icon="plus" size={12} className="mr-[6px]" />
                      New billable metric
                    </span>
                  ),
                }}
              />
            ),
          },
        })}
      />
      {slot}
      {options?.netsuiteEnabled && (
        <Input
          {...ctrl.props.Input("netSuiteOverageItemId", {
            name: "NetSuite overage item ID",
            placeholder: "Enter ID",
          })}
        />
      )}
      <Toggle
        {...ctrl.props.Toggle("quantityConversion", {
          label: "Convert quantity",
        })}
        checked={!!ctrl.get("quantityConversion")}
        tooltip="You can specify a conversion factor which will apply to the quantity before pricing."
        onChange={(e) => {
          if (e) {
            ctrl.update({
              quantityConversion: {},
            });
          } else {
            ctrl.update({
              quantityConversion: undefined,
            });
          }
        }}
      />
      {ctrl.get("quantityConversion") && <QuantityConversion parent={ctrl} />}
      <Toggle
        {...ctrl.props.Toggle("quantityRounding", {
          label: "Round quantity",
        })}
        checked={!!ctrl.get("quantityRounding")}
        tooltip="You can specify a rounding configuration which will apply to the quantity before pricing."
        onChange={(e) => {
          if (e) {
            ctrl.update({
              quantityRounding: {
                roundingMethod: RoundingMethod.HalfUp,
              },
            });
          } else {
            ctrl.update({
              quantityRounding: undefined,
            });
          }
        }}
      />
      {ctrl.get("quantityRounding") && <QuantityRounding parent={ctrl} />}
    </>
  );
};

const useSubscriptionController = useCreateProductController.child(
  Schema.SubscriptionProductInput,
  {
    read(parent) {
      const product = parent.get("product");
      return product?.type === "subscription" ? product : {};
    },
    write(self) {
      return { product: self.getUnvalidatedInputs() };
    },
  },
);
export const SubscriptionProductFields: React.FC<{
  parent: CreateProductController;
  slot?: React.ReactNode;
  options?: {
    netsuiteEnabled?: boolean;
  };
}> = ({ parent, slot, options }) => {
  const ctrl = useSubscriptionController(parent);
  return (
    <>
      {slot}
      {options?.netsuiteEnabled && (
        <Input
          {...ctrl.props.Input("netSuiteOverageItemId", {
            name: "NetSuite overage item ID",
            placeholder: "Enter ID",
          })}
        />
      )}
    </>
  );
};
