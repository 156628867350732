import React from "react";

import { renderDate, renderDateTime } from "lib/time";
import { format } from "date-fns-tz";

import {
  ComposedChart,
  YAxis,
  XAxis,
  Text,
  CartesianGrid,
  Area,
  Line,
  Bar,
  ResponsiveContainer,
  Tooltip as RechartsToolTip,
  ReferenceLine,
  Legend as RechartsLegend,
  TooltipProps,
  TooltipPayload,
} from "recharts";

import styles from "./index.module.less";
import { TooltipContent } from "design-system";
import { GraphSkeleton } from "components/Skeleton";
import * as d3 from "d3-scale";
import { sum } from "lib/util";

// if you want to adjust the size of dots on line charts, do it here
// to make sure that the tooltip cursor matches
const DOT_RADIUS = 5;
const DOT_STROKE = 3;

type AxisProps = {
  payload: {
    value: number;
  };
};

type ReferenceMarker = {
  date: Date;
  label: string;
};

export type TimeSeriesDatum = {
  date: Date;
  value: number;
};

export type TimeSeries = {
  color: string;
  data: TimeSeriesDatum[];
  name?: string;
};

type Props = {
  areas?: TimeSeries[];
  lines?: TimeSeries[];
  bars?: TimeSeries[];
  yValueFormatter?: (n: number) => string;
  isUTC?: boolean;
  stroke?: "solid" | "dashed";
  referenceMarkers?: ReferenceMarker[];
  showLegend?: "above" | "below";
  // when showing the legend, should the sum of values for each line/area
  // appear next to the color?
  showTotalsInLegend?: boolean;
  showHours?: boolean;
  showDay?: boolean;
  loading?: boolean;
  stackBars?: boolean;
  stackAreas?: boolean;
  emptyState?: React.ReactElement;
};

interface Layer extends TooltipPayload {
  fill: string;
}

// In order to "cover" up reference lines when people hover over them we have to create a custom tooltip cursor with a
// white line + a fill color line per layer in the background (to cover the reference line)
const TooltipCursor: React.FC<any> = (props) => {
  const d = `M${props.points[0].x},${props.points[0].y}L${props.points[1].x},${props.points[1].y}`;
  const scale = d3
    .scaleLinear()
    .domain([0, props.maxDatumValue])
    .range([props.points[1].y, props.points[0].y + 8]);

  function getCy(layer: Layer) {
    if (props.stacked) {
      return scale(
        sum(
          ...[...Array(Number(layer.dataKey) + 1).keys()].map((i) =>
            Number(layer.payload[i] ?? 0),
          ),
        ),
      );
    } else {
      return scale(layer.payload[Number(layer.dataKey)]);
    }
  }

  return (
    <g>
      <path
        pointerEvents="none"
        width={props.width}
        height={props.height}
        d={d}
        stroke="white"
        strokeWidth="2"
        shapeRendering="crispEdges"
      />
      {(props?.payload ?? [])
        // paint over any reference lines by drawing a line of the same color as
        // each layer's fill.  Line charts end up reported as a layer with fill
        // set #fff, so we use this property to filter them out - we don't need
        // to blank out the reference line on these layers, otherwise we'd just
        // be adding a white background to the reference line
        .filter((layer: Layer) => {
          return layer.fill !== "#fff";
        })
        .map((layer: Layer) => {
          return (
            <path
              pointerEvents="none"
              width={props.width}
              height={props.height}
              d={`M${props.points[0].x},${scale(
                layer.payload[Number(layer.dataKey)],
              )}L${props.points[1].x},${props.points[1].y}`}
              stroke={layer.fill}
              strokeWidth="2"
              strokeOpacity="80%"
              key={`${Number(layer.dataKey)}`}
              shapeRendering="crispEdges"
            />
          );
        })}
      <path
        pointerEvents="none"
        width={props.width}
        height={props.height}
        strokeDasharray="4 4"
        strokeWidth="1px"
        d={d}
        className={styles.tooltipCursor}
      />
      {(props?.payload ?? []).map((layer: Layer) => {
        // draw a dot on every layer to highlight where the tooltip is
        <circle
          cx={props.points[0].x}
          cy={getCy(layer)}
          r={DOT_RADIUS}
          fill={
            layer.fill && layer.fill === layer.color
              ? layer.fill
              : layer.color
                ? layer.color
                : ""
          }
          stroke="white"
          strokeWidth={DOT_STROKE}
          key={`${Number(layer.dataKey)}`}
        />;
      })}
    </g>
  );
};

export const Graph: React.FC<Props> = (props) => {
  const {
    areas = [],
    lines = [],
    bars = [],
    yValueFormatter,
    isUTC = false,
    stroke = "solid",
    referenceMarkers,
    showLegend = undefined,
    showTotalsInLegend: showLegendTotals = false,
    showHours = false,
    showDay = true,
    stackBars = false,
    stackAreas = false,
    emptyState,
  } = props;

  // empty charts and loading charts get the skeleton treatment
  if (areas.length === 0 && lines.length === 0 && bars.length === 0) {
    if (!props.loading && emptyState !== undefined) {
      return emptyState;
    } else {
      return (
        <GraphSkeleton
          showLegend={showLegend !== undefined}
          showTotalsInLegend={showLegendTotals}
        />
      );
    }
  }

  // This component currently assumes that every graph layer's data array has the same exact dates in the same order
  // TODO: Make this not the case with additional logic
  const combinedLayers = [...areas, ...lines, ...bars];
  if (
    !combinedLayers.every(
      (arr) => arr.data.length === combinedLayers[0].data.length,
    )
  ) {
    throw new Error(
      "Got data arrays with mismatched lengths, meaning the data doesn't all represent the same time period",
    );
  }

  // convert the time series into a recharts-friendly format
  const dataWithUnixTime = (areas[0] ?? lines[0] ?? bars[0]).data.map(
    (datum, datumIndex) => ({
      date: datum.date.valueOf(),
      ...Object.fromEntries(
        areas.map((area, areaIndex) => [
          areaIndex.toString(),
          area.data[datumIndex].value,
        ]),
      ),
      ...Object.fromEntries(
        lines.map((line, lineIndex) => [
          (areas.length + lineIndex).toString(),
          line.data[datumIndex].value,
        ]),
      ),
      ...Object.fromEntries(
        bars.map((bar, barIndex) => [
          (areas.length + lines.length + barIndex).toString(),
          bar.data[datumIndex].value,
        ]),
      ),
    }),
  );

  // if the data in the chart is all 0s, set the y axis to be 0-4
  const maxDatumValue =
    Math.max(
      ...combinedLayers.flatMap((timeSeries) =>
        timeSeries.data.map((datum) => datum.value),
      ),
    ) || 4;

  const YTick: React.FC<AxisProps> = (props) => {
    if (!props.payload) {
      return null;
    }
    let tickValue =
      yValueFormatter !== undefined
        ? yValueFormatter(props.payload.value)
        : props.payload.value.toLocaleString();
    return (
      <Text {...props} x={0} textAnchor="start" className={styles.yAxisTick}>
        {tickValue}
      </Text>
    );
  };

  const referenceMarkerLabels = Object.fromEntries(
    (referenceMarkers || []).map((m) => [m.date.valueOf(), m.label]),
  );

  const XAxisTick: React.FC<AxisProps> = (props) => {
    const date = new Date(props.payload.value);
    const dateLabel = renderDate(date, {
      excludeYear: true,
      isUtc: isUTC,
      excludeUtcLabel: true,
    });
    return (
      <Text {...props} className={styles.xAxisTick}>
        {dateLabel}
      </Text>
    );
  };

  const XBarTick: React.FC<AxisProps> = (props) => {
    const date = new Date(props.payload.value);
    const dateLabel = format(date, "MMM");
    return (
      <Text {...props} className={styles.xAxisTick}>
        {dateLabel}
      </Text>
    );
  };

  const Tooltip: React.FC<TooltipProps> = (props) => {
    if (!props.payload || !props.label) {
      return null;
    }
    const payload: {
      [key: string]: number;
      date: number;
    } = (props.payload || [])[0]?.payload;
    if (!payload) {
      return null;
    }
    const countDisplays: string[] = props.payload
      .map((_, i) => payload[i.toString()])
      .map((value) =>
        yValueFormatter !== undefined
          ? yValueFormatter(value)
          : value.toLocaleString(),
      );

    const dateLabel = showHours
      ? renderDateTime(new Date(props.label), false)
      : renderDate(new Date(props.label), {
          isUtc: isUTC,
          excludeUtcLabel: !isUTC,
          excludeDay: !showDay,
        });

    return (
      <TooltipContent>
        {dateLabel}
        <br />
        {referenceMarkerLabels[props.label] && (
          <>
            {referenceMarkerLabels[props.label]}
            <br />
          </>
        )}
        {countDisplays.map((countDisplay, i) => (
          <p key={i}>
            {props.payload ? props.payload[i].name : ""}: <b>{countDisplay}</b>
          </p>
        ))}
      </TooltipContent>
    );
  };

  const Legend: React.FC<{
    areas: TimeSeries[];
    lines: TimeSeries[];
    bars: TimeSeries[];
    showTotals: boolean;
  }> = (props) => {
    const timeSeries: TimeSeries[] = [...areas, ...lines, ...bars];
    return (
      <ul className={styles.legend}>
        {timeSeries.map((layer, i) => (
          <div className={styles.legendLayer} key={i}>
            <div className={styles.legendLabel}>
              <div
                className={styles.dot}
                style={{ backgroundColor: layer.color }}
              />
              <li>{layer.name}</li>
            </div>
            {props.showTotals && (
              <li className={styles.legendTotal}>
                {layer.data
                  .reduce((sum, { value }) => sum + value, 0)
                  .toLocaleString()}
              </li>
            )}
          </div>
        ))}
      </ul>
    );
  };
  const ticksForBarsOnly =
    bars[0] && bars[0].data.length > 0 && !areas[0] && !lines[0];
  return (
    <div className={styles.container}>
      <ResponsiveContainer>
        <ComposedChart data={dataWithUnixTime} className={styles.chart}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            tick={ticksForBarsOnly ? XBarTick : (XAxisTick as any)}
            // it's unclear why this is necessary,
            // but without the tickFormatter, I can't get a tick to show for each bar.
            tickFormatter={(unixTime) => 0}
            interval="preserveStartEnd"
            padding={{ left: 32, right: 31 }}
            height={16}
            type={ticksForBarsOnly ? "category" : "number"}
            domain={["dataMin", "dataMax"]}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={YTick as any}
            width={32}
            padding={{ top: 8, bottom: 0 }}
            domain={[0, maxDatumValue]}
          />
          {showLegend && (
            <RechartsLegend
              content={
                <Legend
                  areas={areas}
                  lines={lines}
                  bars={bars}
                  showTotals={showLegendTotals}
                />
              }
              verticalAlign={showLegend === "above" ? "top" : "bottom"}
              align="left"
            />
          )}
          {areas.map((area, i) => (
            <Area
              name={area.name}
              dataKey={i}
              stroke={area.color}
              fillOpacity="80%"
              fill={area.color}
              isAnimationActive={false}
              key={i}
              stackId={stackAreas ? "stack" : undefined}
            />
          ))}
          {lines.map((line, i) => (
            <Line
              name={line.name}
              type="linear"
              dataKey={areas.length + i}
              stroke={line.color}
              strokeWidth={2}
              dot={{
                stroke: "white",
                strokeWidth: DOT_STROKE,
                r: DOT_RADIUS,
                fill: line.color,
                strokeDasharray: "",
              }}
              strokeDasharray={stroke === "dashed" ? "3 3" : ""}
              isAnimationActive={false}
              key={i}
            />
          ))}
          {bars.map((bar, i) => (
            <Bar
              name={bar.name}
              dataKey={i}
              fill={bar.color}
              isAnimationActive={false}
              radius={[2, 2, 0, 0]}
              key={i}
              stackId={stackBars ? "stack" : undefined}
              maxBarSize={24}
            />
          ))}
          {(referenceMarkers || []).map((m) => (
            <ReferenceLine
              x={m.date.valueOf()}
              className={styles.referenceMarker}
              key={m.date.valueOf()}
            />
          ))}
          <RechartsToolTip
            content={<Tooltip />}
            cursor={
              <TooltipCursor
                maxDatumValue={maxDatumValue}
                referenceMarkers={referenceMarkers}
                stacked={stackAreas || stackBars}
              />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graph;
