import React, { useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import ReactNumericInput, {
  NumericInputProps as NpmProps,
} from "react-numeric-input";

import { InputContainerProps, InputContainer } from "./InputContainer";

export { DateInput } from "./DateInput";
export { DaysInput } from "./DaysInput";

type CommonInputProps<T> = {
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: (target: EventTarget & T) => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<T>) => void;
  readOnly?: boolean;
  className?: string;
  autoFocus?: boolean;
  maxLength?: number;
};

export type InputProps = InputContainerProps &
  CommonInputProps<HTMLInputElement> & {
    type?: "password" | "search" | "text" | "number";
  };

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <InputContainer {...props}>
        <input
          value={props.value}
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={(e) => props.onChange && props.onChange(e.target.value)}
          onFocus={(e) => props.onFocus && props.onFocus(e.target)}
          onBlur={() => props.onBlur && props.onBlur()}
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
          readOnly={props.readOnly}
          className={props.className}
          autoFocus={props.autoFocus || false}
          maxLength={props.maxLength}
          ref={ref}
        />
      </InputContainer>
    );
  },
);

export type TextAreaProps = InputContainerProps &
  CommonInputProps<HTMLTextAreaElement> & {
    minRows?: number;
  };

export const TextArea: React.FC<TextAreaProps> = (props) => {
  return (
    <InputContainer {...props}>
      <TextareaAutosize
        value={props.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
        minRows={props.minRows ?? 2}
        readOnly={props.readOnly}
        className={props.className}
      />
    </InputContainer>
  );
};

export type NumericInputProps = InputContainerProps & {
  placeholder: string;
  value?: number;
  onChange?: (value: number | null) => void;
  min?: number;
  max?: number;
  precision?: number;
} & Pick<NpmProps, "onBlur" | "onFocus">;

export const NumericInput: React.FC<NumericInputProps> = (props) => {
  useEffect(() => {
    const inputs: HTMLInputElement[] = Array.from(
      document.querySelectorAll(".react-numeric-input input"),
    );
    for (const input of inputs) {
      if (input.value.toString().match(/^[-]?0\.[0-9]$/)) {
        // ensure cursor is at the end
        input.selectionStart = input.value.length;
        input.selectionEnd = input.value.length;
      }
    }
  }, [props.value]);
  return (
    <InputContainer {...props}>
      <ReactNumericInput
        placeholder={props.placeholder}
        value={props.value ?? ""}
        onChange={props.onChange}
        disabled={props.disabled}
        min={props.min}
        max={props.max}
        precision={props.precision}
        noStyle
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
    </InputContainer>
  );
};
