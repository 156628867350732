import React from "react";
import { Link } from "react-router-dom";

import { useEnvironment } from "lib/environmentSwitcher/context";

import styles from "./index.module.less";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";

interface HyperlinkProps extends React.PropsWithChildren {
  /** The path to send users to when the link is clicked. The link will be prefixed automatically with the current env using `prefixUrl()` */
  routePath: string;
  /** Alternative styles to use, when not provided default link styles are applied */
  className?: string;
  /** An optional click handler */
  onClick?: (e: React.MouseEvent) => void;
  /** If set, will replace current state entry in the history stack */
  replace?: boolean;
}

const EmbeddableDashboardInternalLink: React.FC<HyperlinkProps> = ({
  children,
  className,
}) => {
  return <span className={className}>{children}</span>;
};

const FrontendInternalLink: React.FC<HyperlinkProps> = ({
  children,
  routePath,
  className,
  onClick,
  replace,
}) => {
  const { prefixUrl } = useEnvironment();

  return (
    <Link
      to={prefixUrl(routePath)}
      className={className ?? styles.hyperlink}
      onClick={onClick}
      replace={replace}
    >
      {children}
    </Link>
  );
};

export const InternalLink: React.FC<HyperlinkProps> = (props) => {
  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  if (isEmbeddableDashboard) {
    return <EmbeddableDashboardInternalLink {...props} />;
  } else {
    return <FrontendInternalLink {...props} />;
  }
};
