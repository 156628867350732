import { Dayjs } from "lib/date";
import * as Fragments from "./fragments.graphql";
import { Schedule } from "@metronome-industries/schedule-utils";
import { QuantityConversion } from "types/generated-graphql/__types__";

export namespace ProductListItem {
  export type IdFragment = Fragments.ProductListItemIdFragment;
  export type NameFragment = Fragments.ProductListItemNameFragment;
  export type TypeFragment = Fragments.ProductListItemTypeFragment;
  export type TagsFragment = Fragments.ProductListItemTagsFragment;
  export type BillableMetricNameFragment =
    Fragments.ProductListItemBillableMetricNameFragment;
  export type QuantityConversionFragment =
    Fragments.ProductListItemQuantityConversionFragment;
  export type NetsuiteIdsFragment =
    Fragments.ProductListItemNetsuiteIdsFragment;
  export type PricingGroupKeyFragment =
    Fragments.ProductListItemPricingGroupKeyFragment;
  export type PresentationGroupKeyFragment =
    Fragments.ProductListItemPresentationGroupKeyFragment;
  export type ExcludeFreeUsageFragment =
    Fragments.ProductListItemExcludeFreeUsageFragment;
  export type QuantityRoundingFragment =
    Fragments.ProductListItemQuantityRoundingFragment;

  export function getName(product: NameFragment, now: Dayjs) {
    return getCurrent(product, "name", now);
  }

  export function getAllNames(product: NameFragment) {
    const names = new Set([product.initial.name]);

    for (let i = 0; i < product.updates.length; i++) {
      const update = product.updates[i];
      if (update.name) {
        names.add(update.name);
      }
    }

    return Array.from(names);
  }

  export function getTags(product: TagsFragment, now: Dayjs): string[] {
    return getCurrent(product, "tags", now);
  }

  export function printType(product: TypeFragment): string {
    switch (product.__typename) {
      case "FixedProductListItem":
        return "Fixed";
      case "UsageProductListItem":
        return "Usage";
      case "SubscriptionProductListItem":
        return "Subscription";
      case "CompositeProductListItem":
        return "Composite";
      case "ProServiceProductListItem":
        return "Professional Service";
    }
  }

  export function getBillableMetricName(
    product: BillableMetricNameFragment,
    now: Dayjs,
  ) {
    if (product.__typename === "UsageProductListItem") {
      return getCurrent(product, "billable_metric", now)?.name;
    }
  }

  export function getQuantityConversion(
    product: QuantityConversionFragment,
    now: Dayjs,
  ): QuantityConversion | undefined | null {
    if (product.__typename === "UsageProductListItem") {
      return getNullableCurrent(product, "quantity_conversion", now);
    }
  }

  export function getQuantityRounding(
    product: QuantityRoundingFragment,
    now: Dayjs,
  ) {
    if (product.__typename === "UsageProductListItem") {
      return getNullableCurrent(product, "quantity_rounding", now);
    }
  }

  export function getPricingGroupKey(
    product: PricingGroupKeyFragment,
    _now: Dayjs,
  ) {
    if (product.__typename === "UsageProductListItem") {
      return product.current.pricing_group_key;
    }
  }

  export function getPresentationGroupKey(
    product: PresentationGroupKeyFragment,
    _now: Dayjs,
  ) {
    if (product.__typename === "UsageProductListItem") {
      return product.current.presentation_group_key;
    }
  }

  export function getNetsuiteInternalItemId(
    product: NetsuiteIdsFragment,
    now: Dayjs,
  ) {
    return getCurrent(product, "netsuite_internal_item_id", now);
  }

  export function getNetsuiteOverageItemId(
    product: NetsuiteIdsFragment,
    now: Dayjs,
  ) {
    if (
      product.__typename === "UsageProductListItem" ||
      product.__typename === "CompositeProductListItem" ||
      product.__typename === "SubscriptionProductListItem"
    ) {
      return getCurrent(product, "netsuite_overage_item_id", now);
    }
  }

  export function getExcludeFreeUsage(
    product: ExcludeFreeUsageFragment,
    now: Dayjs,
  ) {
    if (product.__typename === "CompositeProductListItem") {
      return getCurrent(product, "exclude_free_usage", now);
    }
  }

  export function getCurrent<
    P extends { initial: any; updates: any[] },
    K extends keyof P["updates"][number] & keyof P["initial"],
  >(product: P, key: K, now: Dayjs): P["initial"][K] {
    for (let i = product.updates.length - 1; i >= 0; i--) {
      const update = product.updates[i];
      if (update[key] !== null && now.isSameOrAfter(update.effective_at)) {
        return update[key];
      }
    }

    return product.initial[key];
  }

  /* Use this when the schema supports key_update field that has `value` and `is_undefined` fields
    This is needed for the fields that can be set to null in the update so we know if the field
    was updated to null or not part of the update (undefined)
  */
  export function getNullableCurrent<
    P extends { initial: any; updates: any[] },
    K extends keyof P["updates"][number] & keyof P["initial"],
  >(product: P, key: K, now: Dayjs): P["initial"][K] {
    const updateKey = `${String(key)}_update`;
    for (let i = product.updates.length - 1; i >= 0; i--) {
      const update = product.updates[i];
      if (
        !update[updateKey].is_undefined &&
        now.isSameOrAfter(update.effective_at)
      ) {
        return update[updateKey].value;
      }
    }

    return product.initial[key];
  }

  export type TagsSchedule = Schedule<{ tags: string[] }>;
  export function getTagsSchedule(product: TagsFragment) {
    const initial = Schedule.infinite<{ tags: string[] }>({
      tags: product.initial.tags,
    });

    return product.updates
      .reduce(
        (schedule, update) =>
          !update.tags
            ? schedule
            : schedule.set(new Date(update.effective_at), null, {
                tags: update.tags,
              }),

        initial,
      )
      .compact();
  }
}
