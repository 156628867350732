import React from "react";
import styles from "./index.module.less";
import classnames from "classnames";
import { Checkbox } from "design-system";

export const BlockSkeleton: React.FC = () => {
  return <div className={styles.block} />;
};

type TextSkeletonWidth = "66%" | "75%" | "80%" | "100%";
const TEXT_SKELETON_WIDTHS = (() => {
  const rec: Record<TextSkeletonWidth, true> = {
    "66%": true,
    "75%": true,
    "80%": true,
    "100%": true,
  };

  return Object.keys(rec) as TextSkeletonWidth[];
})();

function pickTextSkeletonWidth() {
  return TEXT_SKELETON_WIDTHS[
    Math.floor(Math.random() * TEXT_SKELETON_WIDTHS.length)
  ];
}
function widthClassname(width?: TextSkeletonWidth): string {
  switch (width) {
    case "66%":
      return styles.width66;
    case "75%":
      return styles.width75;
    case "80%":
      return styles.width80;
    case "100%":
      return styles.width100;
    default:
      return styles.widthDefault;
  }
}

type TextSkeletonProps = {
  className?: string;
  width?: TextSkeletonWidth;
  size?: "small" | "default";
};

export const TextSkeleton: React.FC<TextSkeletonProps> = ({
  className,
  width,
  size,
}) => (
  <div
    className={classnames(styles.text, widthClassname(width), className, {
      [styles.sizeSmall]: size === "small",
    })}
  />
);

type ParagraphSkeletonProps = {
  className?: string;
  numLines?: number;
};
export const ParagraphSkeleton: React.FC<ParagraphSkeletonProps> = ({
  className,
  numLines,
}) => {
  const widths = React.useMemo(
    () => Array.from({ length: numLines ?? 4 }, () => pickTextSkeletonWidth()),
    [numLines],
  );

  return (
    <div className={className}>
      {widths.map((w, i) => (
        <TextSkeleton key={i} width={w} />
      ))}
    </div>
  );
};

type GraphSkeletonProps = {
  numXMarkers?: number;
  numYMarkers?: number;
  showLegend?: boolean;
  showTotalsInLegend?: boolean;
};

export const GraphSkeleton: React.FC<GraphSkeletonProps> = ({
  numXMarkers = 4,
  numYMarkers = 4,
  showLegend = false,
  showTotalsInLegend = false,
}) => {
  if (numYMarkers < 2) {
    throw new Error("GraphSkeleton must have at least 2 rows");
  }
  if (numXMarkers < 1) {
    throw new Error("GraphSkeleton must have at least 1 column");
  }
  return (
    <div className={styles.graphSkeleton}>
      <table>
        {showLegend && (
          <thead>
            <tr
              className={classnames(styles.firstRow, {
                [styles.firstRowWithTotals]: showTotalsInLegend,
              })}
            >
              <td>
                <div className={styles.dot} />
              </td>
              <td>
                <TextSkeleton className={styles.textSkeletonLong} />
              </td>
            </tr>
          </thead>
        )}
        <tbody>
          {Array.from({ length: numYMarkers }, (_, i) => (
            <tr
              key={i}
              className={classnames(styles.dashedRow, {
                [styles.rowWithTotals]: showTotalsInLegend,
              })}
            >
              <td>
                <TextSkeleton className={styles.textSkeletonShort} />
              </td>
              {Array.from({ length: numXMarkers }, (_, i) => (
                <td className={styles.dashedLine} key={i} />
              ))}
            </tr>
          ))}
          <tr
            className={classnames(styles.solidRow, {
              [styles.rowWithTotals]: showTotalsInLegend,
            })}
          >
            <td>
              <TextSkeleton className={styles.textSkeletonShort} />
            </td>
            {Array.from({ length: numXMarkers }, (_, i) => (
              <td className={styles.solidLine} key={i} />
            ))}
          </tr>
        </tbody>
        <tfoot>
          <tr className={styles.lastRow}>
            {Array.from({ length: numXMarkers }, (_, i) => (
              <td className={styles.column} key={i}>
                <TextSkeleton className={styles.textSkeletonMedium} />
              </td>
            ))}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

type CheckboxSkeletonProps = {
  className?: string;
};

export const CheckboxSkeleton: React.FC<CheckboxSkeletonProps> = ({
  className,
}) => {
  return (
    <Checkbox
      checked={false}
      label={<div className={styles.text} />}
      className={classnames(styles.checkbox, className)}
    />
  );
};
