import React, { useState } from "react";
import Decimal from "decimal.js";

import { PlanDetailsFragment } from "pages/Plan/data/plan.graphql";
import {
  AvatarWithName,
  Body,
  Headline,
  Label,
  NumericInput,
  Select,
  Subtitle,
  Tooltip,
  Icon,
} from "design-system";
import { Column, SimpleTable } from "components/SimpleTable";
import { Statistic, Statistics } from "components/Statistic";
import {
  CreditType,
  CustomCreditType,
  FiatCreditType,
} from "types/credit-types";
import { BlockConfiguration, CreditTypeConversion } from "lib/plans/types";
import {
  creditConversionString,
  displayCreditsInCurrencyWithoutRounding,
  displayCreditTypeName,
  RoundedCurrency,
} from "lib/credits";
import styles from "./index.module.less";
import classnames from "classnames";
import { billingFrequencyFriendlyName } from "../../lib/plans/planPeriods";
import pluralize from "pluralize";
import { ProductContainer } from "components/ProductContainer";
import { CreditInput } from "components/Input";
import {
  calculateCustomPrice,
  clientAdjTypes,
  CustomPricing,
  getPriceAdjustment,
  PriceAdjustment,
  updateCustomPricing,
  validatePPPFAQuantity,
} from "lib/customPricing";
import {
  BillingFrequencyEnum_Enum,
  BillingProviderEnum_Enum,
  ChargeTypeEnum_Enum,
  CollectionScheduleEnum_Enum,
  CompositeChargeTypeEnum_Enum,
  RoundingBehaviorEnum_Enum,
  SeatPrice,
  ServicePeriodStartOnEnum_Enum,
  TieringModeEnum_Enum,
} from "types/generated-graphql/__types__";
import {
  getRowCompositePricingFactors,
  getRowName,
  getRowProrated,
  getRowQuantity,
  getRowRate,
  getRowTiers,
  getRowType,
} from "./row";
import { PlanRecurringGrantPreview } from "./components/PlanRecurringGrantPreview";
import { CopyableID } from "../CopyableID";
import { useFeatureFlag } from "lib/launchdarkly";
import { CompositePricingFactorsPane } from "./components/CompositePricingFactorsPane";
import { generateFeeName } from "../../lib/plans/blockConfiguration";
import classNames from "classnames";
import { DetailedCustomerPlanFragment } from "pages/Contracts/lib/CustomerPlan";
import { formatBillingProvider } from "../../lib/billingProvider/formatBillingProvider";
import { Button } from "../../tenaissance/components/Button";
import { Icon as T10Icon } from "../../tenaissance/components/Icon";

/**
 * Unclear if there is a better way to strongly enforce type the CreditType
 * fields using the graphql definitions. (We have a trigger in the database
 * that enforces Custom and Fiat credit types have/don't have client_id
 * (respectively), but of course the graphql codegen doesn't know about that.
 * The workaround is the casting function, below.
 **/
interface PlanCreditTypeConversion {
  CustomCreditType: CreditType; // would prefer this to be CustomCreditType
  FiatCreditType: CreditType; // same, FiatCreditType would be better
  start_period: string;
  to_fiat_conversion_factor: string;
}
export function castCreditTypes(
  creditTypeConversion: PlanCreditTypeConversion,
): CreditTypeConversion {
  return {
    customCreditType: creditTypeConversion.CustomCreditType as CustomCreditType,
    fiatCreditType: creditTypeConversion.FiatCreditType as FiatCreditType,
    startPeriod: Number(creditTypeConversion.start_period),
    toFiatConversionFactor: Number(
      creditTypeConversion.to_fiat_conversion_factor,
    ),
  };
}

interface PlanMinimum {
  start_period: string;
  value: string;
  CreditType: CreditType;
}

export interface FlatFee {
  value: string;
  metric_minimum: string;
  quantity: string;
  collection_schedule: CollectionScheduleEnum_Enum;
  collection_interval: number;
  is_prorated: boolean | null;
}

interface CompositeChargeTier {
  value: string;
  composite_minimum: string;
}

interface CompositeChargePricingFactor {
  ProductPricingFactor: { id: string; name: string };
}
export interface CompositeCharge {
  CompositeChargeTiers: CompositeChargeTier[];
  CompositeChargePricingFactors: CompositeChargePricingFactor[];
  quantity: number;
  type: CompositeChargeTypeEnum_Enum;
}

export interface PlanPricedProductPricingFactor {
  start_period: string;
  tier_reset_frequency: number;
  skip_ramp: boolean;
  tiering_mode: TieringModeEnum_Enum;
  Prices: {
    value: string;
    metric_minimum: string;
    block_size: string | null;
    block_rounding_behavior: RoundingBehaviorEnum_Enum | null;
  }[];
  SeatPrices: SeatPrice[] | null;
  FlatFees: FlatFee[] | null;
  CompositeCharges: CompositeCharge[] | null;
  ProductPricingFactor: {
    id: string;
    name: string;
    charge_type_enum: ChargeTypeEnum_Enum | null;
    BillableMetric: { name: string } | null;
  };
}

export interface PlanPricedProduct {
  id?: string;
  Product: { id: string; name: string; group_key: string | null };
  PricedProductPricingFactors: PlanPricedProductPricingFactor[];
  CreditType: CreditType;
}

export interface PlanPreviewInfo {
  id?: string;
  name: string;
  description: string;
  billing_frequency: BillingFrequencyEnum_Enum;
  billing_provider: BillingProviderEnum_Enum | null;
  service_period_start_type: ServicePeriodStartOnEnum_Enum;
  default_length_months: number | null;
  TrialSpec: PlanDetailsFragment["TrialSpec"];
  CreditTypeConversions: PlanCreditTypeConversion[];
  PricedProducts: PlanPricedProduct[];
  Minimums: PlanMinimum[];
  net_payment_terms_days?: number;
  RecurringCreditGrants: PlanDetailsFragment["RecurringCreditGrants"];
  Actor?: { id: string; name: string; deprecated_at: string | null } | null;
  seat_billing_frequency: BillingFrequencyEnum_Enum | null;
}

interface PlanPreviewProps {
  theme?: "contracts";
  plan: PlanPreviewInfo;
  collapsible: boolean;
  /* Collapses the "Type" column into the "Charge name" column */
  smallPlanPreview?: boolean;
  // If given, annotates the preview with each place that the current plan
  // differs the previous (using tooltips).
  previousPlan?: PlanPreviewInfo;
  customPricing?: CustomPricing;
  setCustomPricing?: (customPricing: CustomPricing) => void;
  actions?: React.ReactNode;
  badge?: React.ReactNode;
  /** does not render the plan description, creator, etc. section at the top */
  hidePlanBasics?: boolean;
  creditTypeConversionAdjustments?: CreditTypeConversion[];
}

export interface Tier {
  value: string;
  metric_minimum: string;
  quantity?: Decimal;
}

export interface ProductPricesRow {
  chargeType: ChargeTypeEnum_Enum | null;
  name: string;
  type: string | null;
  rate: string | null;
  startPeriod: number;
  tiers: Tier[] | null;
  blockConfiguration: BlockConfiguration | undefined;
  tierResetFrequency: number;
  skipRamp?: boolean;
  quantity?: Decimal;
  isProrated?: boolean;
  collectionSchedule?: CollectionScheduleEnum_Enum;
  metricMinimum?: number;
  compositeMinimum?: number;
  compositePricingFactors?: { id: string; name: string }[];
  compositeChargeType?: CompositeChargeTypeEnum_Enum;
  pricedProductId?: string;
  productPricingFactorId?: string;
  cellKey?: string;
  priceAdj?: PriceAdjustment;
  prevFee?: ProductPricesRow;
}

type TierRows = Array<
  Pick<ProductPricesRow, "name" | "rate" | "quantity" | "chargeType">
>;

interface ProductPreviewInfo {
  productName: string;
  groupKey: string | null;
  creditType: CreditType;
  creditTypeConversion?: CreditTypeConversion;
  fees: ProductPricesRow[];
}

const { Flat: FLAT, Composite: COMPOSITE } = ChargeTypeEnum_Enum;
const LARGE_COLUMN_WIDTH = 300;
const MEDIUM_COLUMN_WIDTH = 230;
const SMALL_COLUMN_WIDTH = 120;
const NO_CREDIT_TYPE = { id: undefined };

const DiffTooltip: React.FC<
  React.PropsWithChildren<{
    content?: React.ReactNode | React.ReactNode[];
  }>
> = (props) => {
  const tooltipContent = props.content ? (
    <>
      Previous pricing:
      {(Array.isArray(props.content) ? props.content : [props.content]).map(
        (row, i) => (
          <div className={styles.diffTooltipRow} key={i}>
            {row}
          </div>
        ),
      )}
    </>
  ) : null;
  return (
    <Tooltip inline content={tooltipContent} disabled={!props.content}>
      <div className={props.content ? styles.diffTarget : ""}>
        {props.children}
      </div>
    </Tooltip>
  );
};

function describeRamp(rampIndex: number, ramps: { startPeriod: number }[]) {
  if (rampIndex === ramps.length - 1) {
    // Last ramp
    return `Ramp ${rampIndex + 1} (Billing periods ${
      ramps[rampIndex].startPeriod + 1
    }+)`;
  }

  if (ramps[rampIndex].startPeriod + 1 === ramps[rampIndex + 1].startPeriod) {
    return `Ramp ${rampIndex + 1} (Billing period ${
      ramps[rampIndex].startPeriod + 1
    })`;
  }

  return `Ramp ${rampIndex + 1} (Billing periods ${
    ramps[rampIndex].startPeriod + 1
  }-${ramps[rampIndex + 1].startPeriod})`;
}

type createCellKeyProps = {
  startPeriod?: number;
  pricedProductId?: string;
  productPricingFactorId?: string;
  metricMinimum?: number;
};

const createCellKey = ({
  startPeriod,
  pricedProductId,
  productPricingFactorId,
  metricMinimum,
}: createCellKeyProps) => {
  return `${startPeriod}:${pricedProductId}:${productPricingFactorId}:${metricMinimum}`;
};

export const collectRamps = (
  plan: PlanPreviewInfo,
  customPricing?: CustomPricing,
  creditTypeConversionAdjustments?: CreditTypeConversion[] | undefined,
) => {
  const invoiceMinimums = plan.Minimums.reduce<Record<string, PlanMinimum[]>>(
    (prev, curr) => {
      if (prev[curr.CreditType.id]) {
        prev[curr.CreditType.id].push(curr);
      } else {
        prev[curr.CreditType.id] = [curr];
      }
      return prev;
    },
    {},
  );

  const rampPeriods = new Set<number>();
  plan.PricedProducts.forEach((pp) => {
    pp.PricedProductPricingFactors.forEach((pppf) => {
      rampPeriods.add(Number(pppf.start_period));
    });
  });
  plan.Minimums.forEach((min) => rampPeriods.add(Number(min.start_period)));

  const ramps: {
    startPeriod: number;
    products: ProductPreviewInfo[];
  }[] = [];

  Array.from(rampPeriods)
    .sort((a, b) => a - b)
    .forEach((startPeriod) => {
      const rampProducts: ProductPreviewInfo[] = [];
      plan.PricedProducts.forEach((pp) => {
        const rampPricingFactors: Record<string, ProductPricesRow> = {};
        pp.PricedProductPricingFactors.forEach((pppf) => {
          if (Number(pppf.start_period) <= startPeriod) {
            if (
              !rampPricingFactors[pppf.ProductPricingFactor.id] ||
              rampPricingFactors[pppf.ProductPricingFactor.id].startPeriod <
                Number(pppf.start_period)
            ) {
              const flatFee = pppf.FlatFees?.[0];
              const row: ProductPricesRow = {
                chargeType: pppf.ProductPricingFactor.charge_type_enum,
                name: getRowName(pppf, plan.billing_frequency),
                type: getRowType(pppf),
                rate: getRowRate(pppf),
                tiers: getRowTiers(pppf),
                startPeriod: Number(pppf.start_period),
                tierResetFrequency: Number(pppf.tier_reset_frequency),
                skipRamp: pppf.skip_ramp,
                quantity: getRowQuantity(pppf),
                isProrated: getRowProrated(pppf),
                collectionSchedule: flatFee?.collection_schedule,
                metricMinimum:
                  pppf.Prices.length === 1 || pppf.FlatFees?.length
                    ? 0
                    : undefined,
                compositeMinimum: pppf.CompositeCharges?.length ? 0 : undefined,
                compositePricingFactors: getRowCompositePricingFactors(pppf),
                compositeChargeType: pppf.CompositeCharges?.[0]?.type,
                pricedProductId: pp.id,
                productPricingFactorId: pppf.ProductPricingFactor.id,
                /* Only attach non-tiered price adjustments */
                priceAdj:
                  customPricing && pppf.Prices.length <= 1
                    ? getPriceAdjustment(
                        pp.id,
                        pppf.ProductPricingFactor.id,
                        Number(pppf.start_period),
                        customPricing,
                        pppf.Prices.length === 1 ? 0 : undefined,
                      )
                    : undefined,
                cellKey: createCellKey({
                  pricedProductId: pp.id,
                  productPricingFactorId: pppf.ProductPricingFactor.id,
                  startPeriod: Number(pppf.start_period),
                  metricMinimum: pppf.Prices.length === 1 ? 0 : undefined,
                }),
                blockConfiguration:
                  pppf.Prices.length && pppf.Prices[0].block_size
                    ? {
                        size: Number(pppf.Prices[0].block_size),
                        roundingBehavior:
                          pppf.Prices[0].block_rounding_behavior ?? undefined,
                      }
                    : undefined,
              };
              rampPricingFactors[pppf.ProductPricingFactor.id] = row;
            }
          }
        });

        const rampCreditTypeConversion = getAdjustedConversionForCreditType(
          pp.CreditType.id,
          startPeriod,
          plan.CreditTypeConversions,
          creditTypeConversionAdjustments,
        );

        rampProducts.push({
          productName: pp.Product.name,
          groupKey: pp.Product.group_key,
          creditType: pp.CreditType,
          creditTypeConversion: rampCreditTypeConversion
            ? castCreditTypes(rampCreditTypeConversion)
            : undefined,
          fees: Object.values(rampPricingFactors).filter(
            (row) => !row.skipRamp,
          ),
        });
      });
      ramps.push({
        startPeriod: startPeriod,
        products: rampProducts,
      });
    });

  return {
    invoiceMinimums,
    rampPeriods,
    ramps,
  };
};

export function formatTrialSpec(
  trialSpec:
    | PlanDetailsFragment["TrialSpec"]
    | DetailedCustomerPlanFragment["trial_spec"],
) {
  if (!trialSpec) {
    return "";
  }
  const trialDays = `${trialSpec.length_in_days} ${pluralize(
    "day",
    Number(trialSpec.length_in_days),
  )}`;
  const spendingCap = trialSpec.TrialSpecSpendingCaps?.length ? (
    <>
      (
      <RoundedCurrency
        amount={new Decimal(trialSpec.TrialSpecSpendingCaps[0].amount)}
        creditType={trialSpec.TrialSpecSpendingCaps[0].CreditType}
      />{" "}
      cap)
    </>
  ) : (
    ""
  );
  return (
    <>
      {trialDays} {spendingCap}
    </>
  );
}
export function formatPlanLength(planLength: number) {
  return planLength === 12
    ? "Annual"
    : planLength % 12 === 0
      ? `${planLength / 12} years`
      : pluralize("month", planLength, true);
}
function setsEqual<T>(a: Set<T>, b: Set<T>) {
  if (a.size !== b.size) return false;
  for (const x of Array.from(a)) {
    if (!b.has(x)) return false;
  }
  return true;
}

function diffPlans(previous: PlanPreviewInfo, current: PlanPreviewInfo) {
  const { rampPeriods: currPeriods } = collectRamps(current);
  const {
    invoiceMinimums: prevMinimums,
    rampPeriods: prevPeriods,
    ramps: prevRamps,
  } = collectRamps(previous);
  const currProducts = current.PricedProducts.map((pp) => pp.Product.name);
  const prevProducts = previous.PricedProducts.map((pp) => pp.Product.name);

  return {
    prevMinimums,
    prevRamps,
    lengthDiff:
      current.default_length_months !== previous.default_length_months
        ? previous.default_length_months ?? "No default length"
        : undefined,
    nameDiff: current.name !== previous.name ? previous.name : undefined,
    descriptionDiff:
      current.description !== previous.description
        ? previous.description
        : undefined,
    productsDiff: !setsEqual(new Set(currProducts), new Set(prevProducts))
      ? prevProducts
      : undefined,
    rampsDiff: !setsEqual(currPeriods, prevPeriods)
      ? prevPeriods.size === 1
        ? "No ramps"
        : prevRamps.map((r, i) => (prevRamps ? describeRamp(i, prevRamps) : ""))
      : undefined,
    trialDiff: current.TrialSpec
      ? !previous.TrialSpec
        ? "No trial"
        : formatTrialSpec(current.TrialSpec) !==
            formatTrialSpec(previous.TrialSpec)
          ? formatTrialSpec(previous.TrialSpec)
          : undefined
      : undefined,
    billingProviderDiff:
      current.billing_provider !== previous.billing_provider
        ? previous.billing_provider
        : undefined,
    seatsBillingFrequencyDiff:
      current.seat_billing_frequency !== previous.seat_billing_frequency
        ? previous.seat_billing_frequency
        : undefined,
  };
}

const inputAdjustmentTypes = [
  {
    label: "No adjustment",
    value: clientAdjTypes.NONE,
  },
  {
    label: "Override",
    value: clientAdjTypes.OVERRIDE,
  },
  {
    label: "Percent increase",
    value: clientAdjTypes.PERCENTAGE_INCREASE,
  },
  {
    label: "Percent decrease",
    value: clientAdjTypes.PERCENTAGE_DECREASE,
  },
  {
    label: "Fixed increase",
    value: clientAdjTypes.FIXED_INCREASE,
  },
  {
    label: "Fixed decrease",
    value: clientAdjTypes.FIXED_DECREASE,
  },
];

const percentageOverrideInputAdjustmentType = [
  {
    label: "Percent override",
    value: clientAdjTypes.PERCENTAGE_OVERRIDE,
  },
];

const quantityInputAdjustmentType = [
  {
    label: "Quantity only",
    value: clientAdjTypes.QUANTITY_ONLY,
  },
];

function getAdjustmentTypesForFee(fee: ProductPricesRow) {
  if (fee.chargeType === COMPOSITE) {
    return [
      inputAdjustmentTypes[0], // No adjustment
      ...(fee.compositeChargeType === CompositeChargeTypeEnum_Enum.Percentage
        ? percentageOverrideInputAdjustmentType
        : [inputAdjustmentTypes[1]]),
      ...quantityInputAdjustmentType,
    ];
  } else if (fee.quantity) {
    return inputAdjustmentTypes.concat(quantityInputAdjustmentType);
  } else {
    return inputAdjustmentTypes;
  }
}

export const PlanPreview: React.FC<PlanPreviewProps> = ({
  plan,
  collapsible,
  smallPlanPreview,
  previousPlan,
  customPricing,
  setCustomPricing,
  hidePlanBasics,
  actions,
  badge,
  theme,
  creditTypeConversionAdjustments,
}) => {
  const [ramp, setRamp] = useState<string>("0");
  const [
    compositePricingFactorsPaneState,
    setCompositePricingFactorsPaneState,
  ] = useState<{
    name: string;
    compositePricingFactorIds: string[];
  } | null>(null);

  const allowAnnualSeats = useFeatureFlag("annual-seats", false);

  const showSeatsBillingFrequency =
    allowAnnualSeats &&
    plan.PricedProducts.some((pp) =>
      pp.PricedProductPricingFactors.some(
        (pppf) =>
          pppf.ProductPricingFactor.charge_type_enum ===
          ChargeTypeEnum_Enum.Seat,
      ),
    );

  const { invoiceMinimums, ramps } = collectRamps(
    plan,
    customPricing,
    creditTypeConversionAdjustments,
  );
  const diffs = previousPlan ? diffPlans(previousPlan, plan) : undefined;

  const canSetBillingProvider = useFeatureFlag(
    "set-billing-provider-on-plan",
    false,
  );

  const RampSelector: React.FC = () => {
    if (ramps.length > 1 || (diffs?.prevRamps && diffs?.prevRamps.length > 1)) {
      return (
        <div className={styles.rampSelector}>
          <DiffTooltip content={diffs?.rampsDiff}>
            <Label>View ramp:</Label>
          </DiffTooltip>
          <div className={styles.rampSelect}>
            <Select
              onChange={(value) => setRamp(value)}
              value={ramp}
              placeholder="Ramp 1"
              options={ramps.map((r, i) => ({
                label: describeRamp(i, ramps),
                value: String(i),
              }))}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const pricingFactorsByProduct = plan.PricedProducts.map((pp) => ({
    name: pp.Product.name,
    productPricingFactors: pp.PricedProductPricingFactors.map((pppf) => ({
      id: pppf.ProductPricingFactor.id,
      name: pppf.ProductPricingFactor.name,
    })),
  }));

  return (
    <div>
      {hidePlanBasics ? null : (
        <div>
          <div className={styles.planOverview}>
            <Subtitle level={1}>Plan overview</Subtitle>
            {actions}
          </div>
          <div className={styles.planInfoBox}>
            <div className={styles.planName}>
              <DiffTooltip content={diffs?.nameDiff}>
                <Subtitle level={4}>{plan.name}</Subtitle>
              </DiffTooltip>
              {badge}
            </div>
            <DiffTooltip content={diffs?.descriptionDiff}>
              <Body level={2} className={styles.descriptionText}>
                {plan.description}
              </Body>
            </DiffTooltip>
            {plan.Actor ? (
              <div className={styles.creatorAndId}>
                <AvatarWithName
                  name={plan.Actor.name}
                  id={plan.Actor.id}
                  deprecated_at={plan.Actor.deprecated_at}
                />
                {plan.id ? <CopyableID id={plan.id} label="plan ID" /> : null}
              </div>
            ) : null}
            <div className={styles.planHeader}>
              <Statistics>
                {plan.default_length_months && (
                  <Statistic caption="Default Plan Length">
                    <DiffTooltip
                      content={
                        diffs?.lengthDiff
                          ? typeof diffs.lengthDiff === "string"
                            ? diffs.lengthDiff
                            : formatPlanLength(diffs.lengthDiff)
                          : undefined
                      }
                    >
                      <Subtitle level={4}>
                        {formatPlanLength(plan.default_length_months)}
                      </Subtitle>
                    </DiffTooltip>
                  </Statistic>
                )}
                <Statistic caption="Plan Interval">
                  <Subtitle level={4}>
                    {billingFrequencyFriendlyName(
                      plan.billing_frequency,
                      plan.service_period_start_type,
                    )}
                  </Subtitle>
                </Statistic>
                {plan.TrialSpec &&
                  Number(plan.TrialSpec.length_in_days) > 0 && (
                    <Statistic caption="Trial">
                      <DiffTooltip content={diffs?.trialDiff}>
                        <Subtitle level={4}>
                          {formatTrialSpec(plan.TrialSpec)}
                        </Subtitle>
                      </DiffTooltip>
                    </Statistic>
                  )}
                {canSetBillingProvider && plan.billing_provider && (
                  <Statistic caption="Billing Provider">
                    <DiffTooltip content={diffs?.billingProviderDiff}>
                      <Subtitle level={4}>
                        {formatBillingProvider(plan.billing_provider)}
                      </Subtitle>
                    </DiffTooltip>
                  </Statistic>
                )}
                {creditTypeConversionAdjustments?.map((adjustment) => (
                  <Statistic
                    caption={`Overage rate for ${adjustment.customCreditType.name}`}
                  >
                    <Subtitle level={4}>
                      {displayCreditsInCurrencyWithoutRounding(
                        new Decimal(adjustment.toFiatConversionFactor ?? ""),
                        adjustment.fiatCreditType,
                        true,
                      )}
                    </Subtitle>
                  </Statistic>
                ))}
                {Object.values(invoiceMinimums).map((creditTypeMinimums, i) => {
                  const creditType = creditTypeMinimums[0].CreditType;
                  const rampMinimum = findMinimumValue(
                    creditTypeMinimums,
                    ramps[Number(ramp)].startPeriod,
                  );
                  const prevMinimums = diffs?.prevMinimums?.[creditType.id];
                  const prevRampMinimum =
                    prevMinimums &&
                    findMinimumValue(
                      prevMinimums,
                      ramps[Number(ramp)].startPeriod,
                    );
                  // Assumes that the creditTypeConversions are sorted in descending start_period order
                  let rampMinimumCreditTypeConversion =
                    getAdjustedConversionForCreditType(
                      creditType.id,
                      ramps[Number(ramp)].startPeriod,
                      plan.CreditTypeConversions,
                      creditTypeConversionAdjustments,
                    );
                  return rampMinimum ? (
                    <Statistic caption="Invoice Minimum" key={i}>
                      <DiffTooltip
                        content={
                          prevRampMinimum && prevRampMinimum !== rampMinimum ? (
                            <Credits
                              value={prevRampMinimum}
                              creditType={creditType}
                              creditTypeConversion={
                                rampMinimumCreditTypeConversion
                                  ? castCreditTypes(
                                      rampMinimumCreditTypeConversion,
                                    )
                                  : undefined
                              }
                            />
                          ) : !prevRampMinimum && previousPlan ? (
                            "--"
                          ) : undefined
                        }
                      >
                        <Subtitle level={4}>
                          <Credits
                            value={rampMinimum}
                            creditType={creditType}
                            creditTypeConversion={
                              rampMinimumCreditTypeConversion
                                ? castCreditTypes(
                                    rampMinimumCreditTypeConversion,
                                  )
                                : undefined
                            }
                          />
                        </Subtitle>
                      </DiffTooltip>
                    </Statistic>
                  ) : null;
                })}
                {plan.net_payment_terms_days != null ? (
                  <Statistic caption="Net Payment Terms">
                    <DiffTooltip content={diffs?.productsDiff}>
                      <Subtitle level={4}>
                        {pluralize("day", plan.net_payment_terms_days, true)}
                      </Subtitle>
                    </DiffTooltip>
                  </Statistic>
                ) : null}
                {showSeatsBillingFrequency ? (
                  <Statistic caption="Seat billing frequency">
                    <DiffTooltip
                      content={
                        diffs?.seatsBillingFrequencyDiff
                          ? "Annual"
                          : diffs?.seatsBillingFrequencyDiff === null
                            ? "Aligned to usage"
                            : undefined
                      }
                    >
                      <Subtitle level={4}>
                        {plan.seat_billing_frequency &&
                        plan.seat_billing_frequency ===
                          BillingFrequencyEnum_Enum.Annual
                          ? "Annual"
                          : "Aligned to usage"}
                      </Subtitle>
                    </DiffTooltip>
                  </Statistic>
                ) : null}
              </Statistics>
            </div>
          </div>
        </div>
      )}
      {compositePricingFactorsPaneState && (
        <CompositePricingFactorsPane
          onRequestClose={() => {
            setCompositePricingFactorsPaneState(null);
          }}
          name={compositePricingFactorsPaneState.name}
          compositePricingFactorIds={
            compositePricingFactorsPaneState.compositePricingFactorIds
          }
          pricingFactorsByProduct={pricingFactorsByProduct}
        />
      )}
      {plan.RecurringCreditGrants.length ? (
        <PlanRecurringGrantPreview
          plan={plan}
          recurringGrant={plan.RecurringCreditGrants[0]}
        />
      ) : null}
      <div>
        {ramps.length > 1 && (
          <div
            className={classnames({
              [styles.customPricingRampHeader]: true,
            })}
          >
            <Subtitle level={1} className={styles.rampDescription}>
              {describeRamp(Number(ramp), ramps)}
            </Subtitle>
            <RampSelector />
          </div>
        )}
        {ramps.length > 0 &&
          ramps[Number(ramp)].products.map((product, i) => (
            <ProductPreviewTable
              theme={theme}
              product={product}
              key={i}
              defaultOpen
              collapsible={collapsible}
              smallPlanPreview={!!smallPlanPreview}
              previousProduct={diffs?.prevRamps?.[Number(ramp)]?.products.find(
                (prevProduct) =>
                  prevProduct.productName === product.productName &&
                  prevProduct.creditType &&
                  prevProduct.creditType.id === product.creditType?.id,
              )}
              customPricing={customPricing}
              setCustomPricing={setCustomPricing}
              onOpenCompositePricingFactorsPane={({
                name,
                compositePricingFactorIds,
              }) =>
                setCompositePricingFactorsPaneState({
                  name,
                  compositePricingFactorIds,
                })
              }
            />
          ))}
      </div>
    </div>
  );
};

const priceAdjustmentInput = (
  setCustomPricing: (customPricing: CustomPricing) => void,
  customPricing: CustomPricing,
  creditType: any,
) => {
  return (fee: ProductPricesRow) => {
    const {
      pricedProductId,
      productPricingFactorId,
      startPeriod,
      priceAdj,
      metricMinimum,
      compositeMinimum,
    } = fee;

    /* If a row doesn't have the appropriate values then it can not have custom pricing */
    if (
      !fee.rate ||
      !pricedProductId ||
      !productPricingFactorId ||
      !creditType
    ) {
      return "";
    }

    const hasOnlyQuantityAdjustment =
      priceAdj?.adjustment_type_enum === clientAdjTypes.QUANTITY_ONLY;

    let creditInputValue: string | undefined;
    // If a price adjustment doesn't exist, the value doesn't exist or only a quantity adjustment exists,
    // clear the credit input.
    if (priceAdj?.value && !hasOnlyQuantityAdjustment) {
      creditInputValue = priceAdj.value.toString();
    }

    const isPercentageInput = (priceAdj?.adjustment_type_enum ?? "").includes(
      "PERCENT",
    );
    return (
      <div className="flex gap-4 rounded-large bg-gray-100 p-4">
        <Select
          className="w-[148px]"
          placeholder=""
          value={priceAdj?.adjustment_type_enum ?? ""}
          options={getAdjustmentTypesForFee(fee)}
          onChange={(value) => {
            const updatedCustomPricing = updateCustomPricing(
              pricedProductId,
              productPricingFactorId,
              startPeriod,
              customPricing,
              metricMinimum,
              value
                ? {
                    value:
                      value === clientAdjTypes.QUANTITY_ONLY
                        ? new Decimal(0)
                        : undefined,
                    adjustment_type_enum: value,
                    metric_minimum: metricMinimum,
                    composite_minimum: compositeMinimum,
                    quantity: priceAdj?.quantity ?? fee.quantity,
                    charge_type_enum: fee.chargeType,
                  }
                : undefined,
            );
            setCustomPricing(updatedCustomPricing);
          }}
        />
        <CreditInput
          allowZeroAmounts
          allowNegativeAmounts={
            priceAdj?.adjustment_type_enum === clientAdjTypes.OVERRIDE
          }
          creditType={isPercentageInput ? NO_CREDIT_TYPE : creditType}
          className="w-[160px]"
          placeholder={isPercentageInput ? "0.0" : "123.45"}
          initialValue={creditInputValue}
          disabled={priceAdj ? hasOnlyQuantityAdjustment : true}
          prefix={isPercentageInput ? "%" : undefined}
          onChange={(value) => {
            const updatedCustomPricing = updateCustomPricing(
              pricedProductId,
              productPricingFactorId,
              startPeriod,
              customPricing,
              metricMinimum,
              {
                value: value !== null ? new Decimal(value) : null,
                adjustment_type_enum: priceAdj?.adjustment_type_enum ?? "",
                metric_minimum: metricMinimum,
                composite_minimum: compositeMinimum,
                quantity: priceAdj?.quantity ?? fee.quantity,
                charge_type_enum: fee.chargeType,
              },
            );
            setCustomPricing(updatedCustomPricing);
          }}
          hideErrorText
        />
      </div>
    );
  };
};

const createArrowColumn = (
  canBeInactive: boolean,
): Column<ProductPricesRow> => {
  return {
    header: "",
    render: (fee: ProductPricesRow) => {
      if (fee.rate) {
        return (
          <div className={styles.arrowContainer}>
            <Icon
              className={classnames(styles.arrow, {
                [styles.inactive]: canBeInactive && !fee.priceAdj,
              })}
              icon="arrowForwardOutline"
            />
          </div>
        );
      }
    },
    width: 0,
  };
};

const quantityInput = (
  fee: ProductPricesRow,
  customPricing: CustomPricing,
  setCustomPricing: (customPricing: CustomPricing) => void,
) => {
  const {
    pricedProductId,
    productPricingFactorId,
    priceAdj,
    startPeriod,
    metricMinimum,
    quantity,
  } = fee;
  if (!fee.rate || !pricedProductId || !productPricingFactorId) {
    return;
  }
  if (quantity === undefined) {
    return <div className={classnames(styles.inactive)}>--</div>;
  }

  const newPriceAdjustmentValue = priceAdj
    ? // A non-quantity-only flat fee adjustment already exists.
      priceAdj.value === undefined &&
      priceAdj.adjustment_type_enum &&
      priceAdj.adjustment_type_enum !== clientAdjTypes.QUANTITY_ONLY
      ? // The adjustment_type is set but there is no PriceAdjustment value yet.
        undefined
      : priceAdj.value
    : new Decimal(0);

  const quantityValid =
    !priceAdj?.quantity ||
    validatePPPFAQuantity(
      priceAdj.quantity,
      fee.chargeType === ChargeTypeEnum_Enum.Composite,
    );

  return (
    <Tooltip
      content={
        fee.chargeType === ChargeTypeEnum_Enum.Composite
          ? "Quantity must be equal to zero or one"
          : "Quantity must be greater than or equal to zero"
      }
      disabled={quantityValid}
    >
      <NumericInput
        placeholder={quantity.toString()}
        value={priceAdj?.quantity?.toNumber()}
        onChange={(value) => {
          let priceAdjustment: PriceAdjustment | undefined;
          if (value !== null) {
            priceAdjustment = {
              value: newPriceAdjustmentValue,
              composite_minimum: priceAdj?.composite_minimum,
              adjustment_type_enum:
                priceAdj?.adjustment_type_enum ?? clientAdjTypes.QUANTITY_ONLY,
              quantity: new Decimal(value),
              charge_type_enum: fee.chargeType,
            };
          } else if (priceAdj) {
            // A flat fee/composite charge adjustment already exists.
            priceAdjustment = {
              value: newPriceAdjustmentValue,
              composite_minimum: priceAdj?.composite_minimum,
              adjustment_type_enum: priceAdj.adjustment_type_enum,
              quantity: null,
              charge_type_enum: fee.chargeType,
            };
          }

          const updatedCustomPricing = updateCustomPricing(
            pricedProductId,
            productPricingFactorId,
            startPeriod,
            customPricing,
            metricMinimum,
            priceAdjustment,
          );
          setCustomPricing(updatedCustomPricing);
        }}
        disabled={false}
        className="w-[64px] text-right font-default text-gray-600"
        error={!quantityValid}
      />
    </Tooltip>
  );
};

interface ProductPreviewTableProps {
  product: ProductPreviewInfo;
  defaultOpen: boolean;
  collapsible: boolean;
  smallPlanPreview: boolean;
  previousProduct?: ProductPreviewInfo;
  customPricing?: CustomPricing;
  setCustomPricing?: (customPricing: CustomPricing) => void;
  onOpenCompositePricingFactorsPane: (compositeCharge: {
    name: string;
    compositePricingFactorIds: string[];
  }) => void;
  theme?: "contracts";
}

const ProductPreviewTable: React.FC<ProductPreviewTableProps> = ({
  product,
  defaultOpen = false,
  collapsible,
  smallPlanPreview,
  previousProduct,
  customPricing,
  setCustomPricing,
  onOpenCompositePricingFactorsPane,
  theme,
}) => {
  const { fees, creditType, creditTypeConversion, productName } = product;
  const [open, setOpen] = useState<boolean>(defaultOpen || !collapsible);
  let customPricingColumns: Column<ProductPricesRow>[] = [];

  if (customPricing) {
    let quantityAdjustmentColumn: Column<ProductPricesRow>;
    let priceAdjustmentColumn: Column<ProductPricesRow>;
    /* Only if we are setting custom pricing do we allow for actions and price adjustment inputs */
    if (setCustomPricing) {
      quantityAdjustmentColumn = {
        header: "QTY",
        render: (fee: ProductPricesRow) => {
          return quantityInput(fee, customPricing, setCustomPricing);
        },
        width: SMALL_COLUMN_WIDTH,
        alignment: "right",
      };

      priceAdjustmentColumn = {
        header: `Price adjustment`,
        render: priceAdjustmentInput(
          setCustomPricing,
          customPricing,
          creditType,
        ),
        width: LARGE_COLUMN_WIDTH,
        alignment: "right",
      };
    } else {
      quantityAdjustmentColumn = {
        header: "QTY",
        render: (fee: ProductPricesRow) => {
          if (!fee.rate) {
            return;
          }
          if (!fee.quantity?.toString() || !fee?.priceAdj?.quantity) {
            return (
              <div className={classnames(styles.credits, styles.inactive)}>
                --
              </div>
            );
          }
          return `${fee.priceAdj?.quantity}`;
        },
        width: SMALL_COLUMN_WIDTH,
        alignment: "right",
      };

      priceAdjustmentColumn = {
        header: `Price adjustment`,
        render: (fee) => {
          if (!fee.rate) {
            return;
          }
          if (
            fee.priceAdj?.adjustment_type_enum === clientAdjTypes.QUANTITY_ONLY
          ) {
            return "Quantity only";
          }
          if (!fee.priceAdj || !fee.priceAdj.value?.toString()) {
            return (
              <div className={classnames(styles.credits, styles.inactive)}>
                --
              </div>
            );
          }
          switch (fee.priceAdj.adjustment_type_enum) {
            case clientAdjTypes.OVERRIDE:
            case clientAdjTypes.PERCENTAGE_OVERRIDE:
              return (
                <Tooltip
                  content="Price overrides are not impacted by plan edits."
                  disabled={!fee.prevFee}
                >
                  Override
                </Tooltip>
              );
            case clientAdjTypes.PERCENTAGE_INCREASE:
              return `${fee.priceAdj.value.toString()}% increase`;
            case clientAdjTypes.PERCENTAGE_DECREASE:
              return `${fee.priceAdj.value.toString()}% decrease`;
            case clientAdjTypes.FIXED_INCREASE:
              return (
                <>
                  <RoundedCurrency
                    amount={fee.priceAdj.value}
                    creditType={creditType}
                  />{" "}
                  increase
                </>
              );
            case clientAdjTypes.FIXED_DECREASE:
              return (
                <>
                  <RoundedCurrency
                    amount={fee.priceAdj.value}
                    creditType={creditType}
                  />{" "}
                  decrease
                </>
              );
            default:
              throw Error("Unknown price adjustment");
          }
        },
        width: SMALL_COLUMN_WIDTH,
        alignment: "right",
      };
    }

    customPricingColumns = [
      quantityAdjustmentColumn,
      createArrowColumn(!setCustomPricing),
      priceAdjustmentColumn,
      createArrowColumn(true),
      {
        header: "New price",
        render: (fee) => {
          if (!fee.rate) {
            return "";
          }

          let newPrice = "";
          if (
            fee.priceAdj?.value &&
            (fee.priceAdj?.quantity === undefined ||
              (fee.priceAdj?.quantity !== null &&
                fee.priceAdj.quantity.greaterThan(new Decimal(0))) ||
              fee.chargeType === COMPOSITE)
          ) {
            const listPrice =
              fee.chargeType === COMPOSITE &&
              fee.compositeChargeType ===
                CompositeChargeTypeEnum_Enum.Percentage
                ? new Decimal(fee.rate).mul(100)
                : new Decimal(fee.rate);
            newPrice = calculateCustomPrice(
              fee.priceAdj.value,
              fee.priceAdj.adjustment_type_enum,
              listPrice,
            ).toString();
          }

          return fee.chargeType === COMPOSITE &&
            fee.compositeChargeType ===
              CompositeChargeTypeEnum_Enum.Percentage ? (
            <Percentage
              value={newPrice}
              default="--"
              quantity={fee.priceAdj?.quantity ?? fee.quantity}
              canBeInactive={!fee.priceAdj}
            />
          ) : (
            <Credits
              value={newPrice}
              creditType={creditType}
              chargeType={fee.chargeType}
              default="--"
              quantity={fee.priceAdj?.quantity ?? undefined}
              canBeInactive={!fee.priceAdj}
              isProrated={fee.isProrated}
              isCustomPricing
            />
          );
        },
        width: SMALL_COLUMN_WIDTH,
        alignment: "right",
      },
    ];
  }

  return (
    <ProductContainer
      className={classnames(styles.productPreview, { [styles.expanded]: open })}
      theme={theme}
      title={
        <Headline
          level={5}
          className={classNames(
            { [styles.contracts]: theme === "contracts" },
            styles.productName,
          )}
        >
          {productName}
        </Headline>
      }
      groupKey={product.groupKey}
      button={
        collapsible && (
          <Button
            theme="secondary"
            text={open ? "Collapse" : "Expand"}
            onClick={() => setOpen(!open)}
            className="mr-12"
          />
        )
      }
    >
      <div className={styles.productCreditType}>
        <Statistics>
          <Statistic caption="Pricing Unit">
            <DiffTooltip
              content={
                previousProduct
                  ? creditConversionDiff(
                      previousProduct.creditTypeConversion,
                      product.creditTypeConversion,
                    )
                  : undefined
              }
            >
              <Subtitle level={4}>
                {creditType
                  ? creditTypeConversion
                    ? `${displayCreditTypeName(
                        creditType,
                      )} (${creditConversionString(creditTypeConversion)})`
                    : displayCreditTypeName(creditType)
                  : "--"}
              </Subtitle>
            </DiffTooltip>
          </Statistic>
        </Statistics>
      </div>

      {open && (
        <div className={styles.table}>
          <SimpleTable
            noBottomBorder
            columns={[
              {
                header: "Charges",
                render: (fee, isSubRow) => {
                  const prevFeeName = fee.prevFee
                    ? isSubRow
                      ? fee.prevFee.name
                      : generateFeeName(
                          fee.prevFee.name,
                          fee.prevFee.blockConfiguration,
                          fee.tierResetFrequency,
                        )
                    : undefined;

                  const newFeeName = isSubRow
                    ? fee.name
                    : generateFeeName(
                        fee.name,
                        fee.blockConfiguration,
                        fee.tierResetFrequency,
                      );

                  return (
                    <DiffTooltip
                      content={
                        prevFeeName !== newFeeName ? prevFeeName : undefined
                      }
                    >
                      <div className="flex items-baseline">
                        {fee.productPricingFactorId && (
                          <CopyableID
                            id={fee.productPricingFactorId}
                            label="charge ID"
                            hideID
                          />
                        )}
                        {smallPlanPreview ? (
                          <div className={styles.chargeNameCell}>
                            {/* If there is no type make the charge name similar in styles to the type */}
                            {fee.type ? (
                              <>
                                <div>{newFeeName}</div>
                                <div className={styles.type}>{fee.type}</div>
                              </>
                            ) : (
                              <div className={styles.type}>{newFeeName}</div>
                            )}
                          </div>
                        ) : (
                          <Body level={2}>{newFeeName}</Body>
                        )}
                      </div>
                    </DiffTooltip>
                  );
                },
                width: LARGE_COLUMN_WIDTH,
              },
              ...(smallPlanPreview
                ? []
                : [
                    {
                      header: "Type",
                      render: (fee: ProductPricesRow) => {
                        const typeText = fee.type ?? "";
                        let diffFeeTypeText =
                          fee.prevFee?.type !== fee.type ||
                          fee.prevFee?.collectionSchedule !==
                            fee.collectionSchedule ||
                          fee.prevFee?.isProrated !== fee.isProrated
                            ? fee.prevFee?.type
                              ? fee.prevFee?.collectionSchedule ===
                                CollectionScheduleEnum_Enum.Advance
                                ? `${fee.prevFee.type} (Billed in advance)`
                                : fee.prevFee.type
                              : ""
                            : undefined;
                        if (diffFeeTypeText && fee.prevFee?.isProrated) {
                          diffFeeTypeText += " (prorate if applicable)";
                        }

                        return (
                          <DiffTooltip content={diffFeeTypeText}>
                            <Body level={2} className={styles.feeTypeText}>
                              {typeText}
                            </Body>
                          </DiffTooltip>
                        );
                      },
                      width: customPricing
                        ? SMALL_COLUMN_WIDTH
                        : LARGE_COLUMN_WIDTH,
                    },
                  ]),
              {
                header: customPricing ? "List price" : "List price (Quantity)",
                render: (fee) => {
                  if (!creditType) {
                    return "--";
                  }
                  return fee.chargeType === COMPOSITE ? (
                    <div className={styles.compositePrice}>
                      <Tooltip
                        inline={true}
                        content={
                          fee.compositePricingFactors?.length
                            ? `${fee.compositePricingFactors.length} associated charges`
                            : undefined
                        }
                      >
                        <button
                          onClick={() => {
                            onOpenCompositePricingFactorsPane({
                              name: fee.name,
                              compositePricingFactorIds: (
                                fee.compositePricingFactors ?? []
                              ).map((cpf) => cpf.id),
                            });
                          }}
                          className="focus:none mr-4 flex h-[20px] w-[20px] items-center justify-center shadow-none"
                        >
                          <T10Icon icon="list" size={12} />
                        </button>
                      </Tooltip>
                      <DiffTooltip
                        content={compositeChargeDiff(
                          fee,
                          fee.prevFee,
                          creditType,
                        )}
                      >
                        {fee.compositeChargeType ===
                        CompositeChargeTypeEnum_Enum.Percentage ? (
                          <Percentage
                            value={new Decimal(fee.rate ?? 0)
                              .mul(100)
                              .toString()}
                            quantity={fee.quantity}
                          />
                        ) : (
                          <Credits
                            value={fee.rate}
                            creditType={creditType}
                            quantity={fee.quantity}
                            hasDiff={
                              !!compositeChargeDiff(
                                fee,
                                fee.prevFee,
                                creditType,
                              )
                            }
                          />
                        )}
                      </DiffTooltip>
                    </div>
                  ) : (
                    <DiffTooltip
                      content={flatFeeDiff(fee, fee.prevFee, creditType)}
                    >
                      <Credits
                        value={fee.rate}
                        chargeType={fee.chargeType}
                        creditType={creditType}
                        quantity={customPricing ? undefined : fee.quantity}
                        isProrated={fee.isProrated}
                        hasDiff={!!flatFeeDiff(fee, fee.prevFee, creditType)}
                      />
                    </DiffTooltip>
                  );
                },
                width: customPricing ? SMALL_COLUMN_WIDTH : MEDIUM_COLUMN_WIDTH,
                alignment: "right",
              },
              ...customPricingColumns,
            ]}
            data={fees.map((fee, feeIndex) => {
              const tierSort = (a: Tier, b: Tier) =>
                Number(a.metric_minimum) < Number(b.metric_minimum) ? -1 : 1;
              function rowFromTier(tier: Tier, i: number): ProductPricesRow {
                return {
                  chargeType: fee.chargeType,
                  name: `Tier ${i + 1} (> ${Number(
                    tier.metric_minimum,
                  ).toLocaleString()})`,
                  type: null,
                  rate: tier.value,
                  quantity: tier.quantity,
                  startPeriod: Number(fee.startPeriod),
                  tierResetFrequency: fee.tierResetFrequency,
                  tiers: null,
                  metricMinimum: Number(tier.metric_minimum),
                  pricedProductId: fee.pricedProductId,
                  productPricingFactorId: fee.productPricingFactorId,
                  priceAdj: customPricing
                    ? getPriceAdjustment(
                        fee.pricedProductId,
                        fee.productPricingFactorId,
                        fee.startPeriod,
                        customPricing,
                        Number(tier.metric_minimum),
                      )
                    : undefined,
                  cellKey: createCellKey({
                    pricedProductId: fee.pricedProductId,
                    productPricingFactorId: fee.productPricingFactorId,
                    startPeriod: fee.startPeriod,
                    metricMinimum: Number(tier.metric_minimum),
                  }),
                  blockConfiguration: fee.blockConfiguration,
                };
              }

              let prevFee:
                | (ProductPricesRow & { tierRows?: TierRows })
                | undefined = previousProduct?.fees[feeIndex];
              if (prevFee?.tiers) {
                prevFee = {
                  ...prevFee,
                  tierRows: [...prevFee.tiers].sort(tierSort).map(rowFromTier),
                };
              }

              if (!fee.tiers) {
                return { ...fee, prevFee };
              }
              const sortedPrices = [...fee.tiers].sort(tierSort);
              const subRows: ProductPricesRow[] = sortedPrices.map(
                (price, i) => {
                  const row = rowFromTier(price, i);
                  /* Only show diffs on individual tiers if the number of tiers is still the same. */
                  const unchangedTierCount =
                    fee.tiers?.length === prevFee?.tiers?.length;
                  /* If we don't change the tier count show the difference between the current name and previous name */
                  const previousTierName = unchangedTierCount
                    ? prevFee?.tierRows?.[i]?.name ?? ""
                    : row.name;
                  /* If we don't change the tier count show the difference between the current rate and previous rate */
                  const previousTierRate = unchangedTierCount
                    ? prevFee?.tierRows?.[i]?.rate ?? ""
                    : row.rate;
                  const previousTierQuantity = unchangedTierCount
                    ? prevFee?.tierRows?.[i]?.quantity
                    : row.quantity;
                  return {
                    ...row,
                    subRows: null,
                    prevFee: {
                      ...prevFee,
                      chargeType: row.chargeType,
                      name: previousTierName,
                      rate: previousTierRate,
                      quantity: previousTierQuantity,
                      type: null,
                      tiers: null,
                      startPeriod: Number(fee.startPeriod),
                      tierResetFrequency: fee.tierResetFrequency,
                      pricedProductId: fee.pricedProductId,
                      productPricingFactorId: fee.productPricingFactorId,
                      blockConfiguration: fee.blockConfiguration, // never show block configuration in subrow diff
                    },
                  };
                },
              );
              return {
                ...fee,
                subRows,
                prevFee,
              };
            })}
          />
        </div>
      )}
    </ProductContainer>
  );
};

const Percentage: React.FC<{
  value: string | null | undefined;
  quantity: Decimal | null | undefined;
  compositeChargeNames?: string[];
  default?: string;
  canBeInactive?: boolean;
}> = ({ value, quantity, default: defaultStr, canBeInactive }) => {
  if (!value) {
    return (
      <div
        className={classnames(styles.credits, {
          [styles.inactive]: canBeInactive,
        })}
      >
        <div>{defaultStr ?? ""}</div>
      </div>
    );
  }
  return (
    <div className={styles.credits}>
      {new Decimal(value).toString()}% (×{(quantity ?? 0).toString()})
    </div>
  );
};

const Credits: React.FC<{
  value: string | null | undefined;
  chargeType?: ChargeTypeEnum_Enum | null;
  creditType: CreditType | null;
  quantity?: Decimal;
  isProrated?: boolean | null;
  default?: string;
  creditTypeConversion?: CreditTypeConversion;
  hasDiff?: boolean;
  canBeInactive?: boolean;
  isCustomPricing?: boolean;
}> = ({
  value,
  creditType,
  chargeType,
  quantity,
  isProrated,
  default: defaultStr,
  creditTypeConversion,
  hasDiff,
  canBeInactive,
  isCustomPricing, // TODO: This is a hack to handle conflicts between fixed fee tiers and custom pricing
}) => {
  if (
    !value &&
    quantity &&
    !isCustomPricing &&
    chargeType !== ChargeTypeEnum_Enum.Seat
  ) {
    return (
      <div className={styles.credits}>
        <div>
          Tiered (<>×{quantity.toString()}</>)
        </div>
      </div>
    );
  }
  if (!(value && creditType)) {
    return (
      <div
        className={classnames(styles.credits, {
          [styles.inactive]: canBeInactive,
        })}
      >
        <div>{defaultStr ?? ""}</div>
      </div>
    );
  }

  const creditsSubtotal = new Decimal(value);
  const creditsTotal = quantity
    ? new Decimal(value).mul(quantity)
    : creditsSubtotal;
  const proratedStr =
    chargeType === FLAT
      ? isProrated
        ? " (will be prorated)"
        : " (will NOT be prorated)"
      : "";

  return (
    <Tooltip
      disabled={hasDiff}
      content={
        creditTypeConversion && quantity ? (
          <>
            {displayCreditsInCurrencyWithoutRounding(creditsTotal, creditType)}
            {creditConversionString(creditTypeConversion)}) {proratedStr}
          </>
        ) : quantity ? (
          <>
            {displayCreditsInCurrencyWithoutRounding(creditsTotal, creditType)}
            {proratedStr}
          </>
        ) : undefined
      }
    >
      <div className={styles.credits}>
        <div>
          {creditTypeConversion ? (
            <>
              {displayCreditsInCurrencyWithoutRounding(
                creditsSubtotal,
                creditType,
              )}{" "}
              ({creditConversionString(creditTypeConversion)})
            </>
          ) : (
            displayCreditsInCurrencyWithoutRounding(creditsSubtotal, creditType)
          )}
          {quantity && <> (×{quantity.toString()})</>}
        </div>
      </div>
    </Tooltip>
  );
};

function getValueDiff(
  fee: Pick<ProductPricesRow, "rate" | "chargeType">,
  prevFee: Pick<ProductPricesRow, "rate" | "compositeChargeType"> | undefined,
) {
  if (prevFee?.rate && fee.rate !== prevFee.rate) {
    return fee.chargeType === COMPOSITE &&
      prevFee.compositeChargeType === CompositeChargeTypeEnum_Enum.Percentage
      ? new Decimal(prevFee.rate).mul(100).toString()
      : prevFee.rate;
  }
  return undefined;
}

function getQuantityDiff(
  fee: Pick<ProductPricesRow, "quantity">,
  prevFee: Pick<ProductPricesRow, "quantity"> | undefined,
) {
  return prevFee?.quantity && !fee.quantity?.eq(prevFee.quantity)
    ? prevFee.quantity
    : undefined;
}

const flatFeeDiff = (
  fee: Pick<ProductPricesRow, "rate" | "tiers" | "quantity" | "chargeType">,
  prevFee:
    | (Pick<ProductPricesRow, "rate" | "tiers" | "quantity"> & {
        tierRows?: TierRows;
      })
    | undefined,
  creditType: CreditType | null,
) => {
  if (
    prevFee &&
    fee.tiers?.length !== prevFee.tiers?.length &&
    prevFee.tierRows
  ) {
    // If the number of tiers changed, show the entire prior set of tiers
    // in a single tooltip.
    return prevFee.tierRows.map((row) => {
      return (
        <>
          <div className={styles.diffTooltipTierName}>{row.name}</div>
          <div className={styles.diffTooltipTierSeparator}>-</div>
          <div>
            <Credits
              value={row.rate}
              creditType={creditType}
              chargeType={fee.chargeType}
              quantity={row.quantity}
            />
          </div>
        </>
      );
    });
  } else {
    const valueDiff = getValueDiff(fee, prevFee);
    const quantityDiff = getQuantityDiff(fee, prevFee);
    if (valueDiff || quantityDiff) {
      return (
        <Credits
          value={valueDiff ?? fee.rate}
          creditType={creditType}
          chargeType={fee.chargeType}
          quantity={quantityDiff ?? fee.quantity}
        />
      );
    }
    return undefined;
  }
};

const compositeChargeDiff = (
  fee: Pick<
    ProductPricesRow,
    | "rate"
    | "quantity"
    | "compositePricingFactors"
    | "chargeType"
    | "compositeChargeType"
  >,
  prevFee:
    | Pick<
        ProductPricesRow,
        "rate" | "quantity" | "compositePricingFactors" | "compositeChargeType"
      >
    | undefined,
  creditType: CreditType,
) => {
  if (prevFee) {
    const valueDiff = getValueDiff(fee, prevFee);
    const quantityDiff = getQuantityDiff(fee, prevFee);
    const prevFeeCompositePricingFactors = JSON.stringify(
      prevFee?.compositePricingFactors?.sort(),
    );
    const feeCompositePricingFactors = JSON.stringify(
      fee.compositePricingFactors
        ?.map((cpf) => ({ __typename: "ProductPricingFactor", ...cpf }))
        .sort(),
    );
    const pricingFactorsDiff =
      prevFeeCompositePricingFactors !== feeCompositePricingFactors;

    const typeDiff = fee.compositeChargeType !== prevFee?.compositeChargeType;

    if (valueDiff || quantityDiff || pricingFactorsDiff || typeDiff) {
      return [
        prevFee.compositeChargeType ===
        CompositeChargeTypeEnum_Enum.Percentage ? (
          <Percentage
            value={valueDiff ?? fee.rate}
            quantity={quantityDiff ?? fee.quantity}
          />
        ) : (
          <Credits
            value={valueDiff}
            creditType={creditType}
            quantity={quantityDiff}
            hasDiff={true}
          />
        ),
        <div className={styles.diffTooltipComposite}>
          Previously selected charges:{" "}
          <ul className={styles.diffTooltipCompositePricingFactor}>
            {prevFee.compositePricingFactors?.map((cpf, i) => (
              <li key={i}>{cpf.name}</li>
            ))}
          </ul>
        </div>,
      ];
    }
  }
  return undefined;
};

const creditConversionDiff = (
  prevConversion: CreditTypeConversion | undefined,
  currConversion: CreditTypeConversion | undefined,
) => {
  if (!currConversion && !prevConversion) {
    return undefined;
  }
  if (!prevConversion) {
    return "No in-arrears pricing";
  }
  if (!currConversion) {
    return `In-arrears pricing: ${creditConversionString(prevConversion)}`;
  }
  if (
    currConversion.customCreditType.id !== prevConversion.customCreditType.id ||
    currConversion.fiatCreditType.id !== prevConversion.fiatCreditType.id ||
    currConversion.toFiatConversionFactor !==
      prevConversion.toFiatConversionFactor
  ) {
    return creditConversionString(prevConversion);
  }

  return undefined;
};

export function findMinimumValue(
  minimums: PlanMinimum[],
  rampStartPeriod: number,
): string | null {
  const latestMatchingMinimum = minimums
    .sort((a, b) => Number(b.start_period) - Number(a.start_period))
    .find((min) => Number(min.start_period) <= rampStartPeriod);
  return latestMatchingMinimum ? latestMatchingMinimum.value : null;
}

function getAdjustedConversionForCreditType(
  creditTypeId: string,
  startPeriod: number,
  conversions: PlanCreditTypeConversion[] | undefined,
  adjustments: CreditTypeConversion[] | undefined,
): PlanCreditTypeConversion | undefined {
  let rampCreditTypeConversion = conversions?.find(
    (c) =>
      c.CustomCreditType.id === creditTypeId &&
      Number(c.start_period) <= startPeriod,
  );
  const rampCreditTypeConversionAdjustment =
    adjustments == undefined
      ? undefined
      : [...adjustments]
          // We need to find the adjustment with the largest start period less than the
          // target start period, so we sort in descending order of start_period.
          .sort((left, right) => right.startPeriod - left.startPeriod)
          .find(
            (c) =>
              c.customCreditType.id === creditTypeId &&
              c.startPeriod <= startPeriod,
          );
  if (
    rampCreditTypeConversion &&
    rampCreditTypeConversionAdjustment?.toFiatConversionFactor
  ) {
    rampCreditTypeConversion = {
      ...rampCreditTypeConversion,
      to_fiat_conversion_factor: new Decimal(
        rampCreditTypeConversionAdjustment.toFiatConversionFactor,
      ).toString(),
    };
  }
  return rampCreditTypeConversion;
}
