import React from "react";

import { twMerge } from "../../twMerge";
import styles from "./index.module.css";

type Props = {
  className?: string;
  overrideColor?: boolean;
};

export const LoadingSpinner: React.FC<Props> = ({
  className,
  overrideColor = false,
}: Props) => {
  return (
    <div
      className={twMerge(
        styles.spinner,
        className,
        !overrideColor && styles.setBorderColor,
      )}
    />
  );
};

export default LoadingSpinner;
