import React from "react";
import type { DocumentNode } from "graphql";
import { useAuthCheck } from "lib/useAuthCheck";
import { Button, ButtonProps } from "tenaissance/components/Button";
import { Tooltip } from "../../tenaissance/components/Tooltip";
import {
  IconButton,
  IconButtonProps,
} from "../../tenaissance/components/IconButton";

type GatedButtonProps = ButtonProps & {
  doc: DocumentNode | DocumentNode[] | undefined;
  variables?: { [key: string]: any } | { [key: string]: any }[];
  routePath?: string;
};

export const GatedButton: React.FC<GatedButtonProps> = (props) => {
  const { doc, variables, routePath, ...otherProps } = props;
  const buttonProps = {
    ...otherProps,
    routePath,
  };
  const { loading, allowed } = useAuthCheck(doc, true, variables);

  if (buttonProps.disabled) {
    return <Button {...buttonProps} />;
  }

  if (loading) {
    return <Button {...buttonProps} loading disabled />;
  }

  if (!allowed) {
    return (
      <Tooltip label="You do not have permission to perform this action">
        <Button {...buttonProps} disabled />
      </Tooltip>
    );
  }

  return <Button {...buttonProps} />;
};

type GatedIconButtonProps = IconButtonProps & {
  doc: DocumentNode | DocumentNode[] | undefined;
  variables?: { [key: string]: any } | { [key: string]: any }[];
};

export const GatedIconButton: React.FC<GatedIconButtonProps> = (props) => {
  const { doc, variables, ...buttonProps } = props;
  const { loading, allowed } = useAuthCheck(doc, true, variables);

  if (buttonProps.disabled) {
    return <IconButton {...buttonProps} />;
  }

  if (loading) {
    return <IconButton {...buttonProps} loading disabled />;
  }

  if (!allowed) {
    return (
      <Tooltip label="You do not have permission to perform this action">
        <IconButton {...buttonProps} disabled />
      </Tooltip>
    );
  }

  return <IconButton {...buttonProps} />;
};
