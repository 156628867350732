import React from "react";
import {
  ApolloClient,
  ApolloProvider as UnwrappedApolloProvider,
  from,
  HttpLink,
} from "@apollo/client";
import { GraphQLError } from "graphql";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { defaultDataIdFromObject, InMemoryCache } from "@apollo/client/cache";
import * as Sentry from "@sentry/react";
import { possibleTypes } from "lib/apollo/generated/possible-types";

interface ApolloProviderProps extends React.PropsWithChildren {}

import { AuthContext } from "../AuthContext";

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        /* Allow for the list and detail queries to pull from the same cache */
        BillableMetric_by_pk: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: "BillableMetric",
              id: (args as { id: string }).id,
            });
          },
        },
      },
    },
  },
  dataIdFromObject(responseObject, context) {
    // Users and Actors are two different type names, but should be treated the same in the cache, since
    // Actor is just a "view" on top of users and tokens.
    const specialCasedActorTypes = ["User", "Actor"];
    if (
      responseObject.__typename &&
      responseObject.id &&
      specialCasedActorTypes.includes(responseObject.__typename)
    ) {
      return defaultDataIdFromObject({
        ...responseObject,
        __typename: "Actor",
      });
    }

    // If we get an object back with a missing ID, we don't want to cache it.
    // Otherwise, e.g. draft invoices end up showing duplicate data in the UI
    // because they all have the same (null) ID. Returning undefined, as we do
    // here implicitly, causes Apollo to not cache the object.
    if (responseObject.id !== null) {
      return defaultDataIdFromObject(responseObject);
    }
  },
});

function getGraphqlUrl() {
  if (window.location.search.includes("graphqlUrl=")) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("graphqlUrl") || process.env.GRAPHQL_URL;
  }

  return process.env.GRAPHQL_URL;
}

function logErrorToSentry(error: GraphQLError) {
  console.log("Sending error to sentry", error);
  const msg = `GraphQL Error: ${error.message}`;
  if (!Sentry.getCurrentHub().getClient()?.getOptions().enabled) {
    // If we're not reporting to Sentry (e.g. in dev environment), log
    // the error to the console.
    console.group(msg);
    console.dir(error);
    console.groupEnd();
  }
  Sentry.captureException(new Error(msg), (s) => {
    const fingerprint = [error.message];
    if (error.path) {
      const path = error.path.join(" > ");
      s.setTag("path", path);
      fingerprint.push(path);
    }
    if (error.extensions && error.extensions.code) {
      s.setTag("code", error.extensions.code);
      fingerprint.push(error.extensions.code);
    }
    s.setFingerprint(fingerprint);
    return s;
  });
}

const createErrorLink = () =>
  onError(({ networkError, graphQLErrors }) => {
    const networkErrors = (((networkError as any) || {}).result || []).flatMap(
      (networkError: any) => networkError.errors || [],
    );
    const isBadJWT = networkErrors
      .concat(graphQLErrors)
      .find((error: any) => error?.extensions?.code === "invalid-jwt");

    if (isBadJWT) {
      return;
    }

    for (const error of graphQLErrors || []) {
      logErrorToSentry(error);
    }
  });

export const ApolloProvider: React.FC<ApolloProviderProps> = ({ children }) => {
  const { authToken } = AuthContext.useContainer();

  const graphqlUrl = getGraphqlUrl();
  const unbatchedHttpLink = new HttpLink({
    uri: graphqlUrl,
  });

  const asyncAuthLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${authToken}`,
      },
    };
  });

  const client = new ApolloClient({
    cache,
    link: from([from([createErrorLink(), asyncAuthLink, unbatchedHttpLink])]),
  });

  return (
    <UnwrappedApolloProvider client={client}>
      {children}
    </UnwrappedApolloProvider>
  );
};
