import React from "react";
import { Tooltip, Badge } from "design-system";

interface Props {
  message: string;
}

export const TinyErrorState: React.FC<Props> = ({ message }) => {
  return (
    <Tooltip inline content={message}>
      <Badge theme="error" type="dark">
        Error
      </Badge>
    </Tooltip>
  );
};
