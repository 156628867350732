import { Input, NumericInput, Select } from "design-system";
import { FormController } from "lib/FormController/FormController";
import React from "react";
import { ConversionOperation } from "types/generated-graphql/__types__";
import { Schema } from "../Schema";
import { z } from "zod";

interface Props {
  parent: FormController<any>;
}

const useRootCtrl = FormController.makeChildHook(
  z.object({ quantityConversion: Schema.QuantityConversion }),
  {
    read: (parent) => parent.get("quantityConversion"),
    write: (child) => ({ quantityConversion: child.getUnvalidatedInputs() }),
    debugName: "QuantityConversion",
  },
);

const useQuantityConversion = useRootCtrl.child(Schema.QuantityConversion, {
  read: (parent) => parent.get("quantityConversion"),
  write: (child) => ({ quantityConversion: child.getUnvalidatedInputs() }),
  debugName: "QuantityConversion",
});

export const QuantityConversion: React.FC<Props> = ({ parent }) => {
  const ctrl = useQuantityConversion(parent);
  return (
    <div className="ml-32 grid gap-12">
      <Select
        {...ctrl.props.Select("operation", {
          name: "Operator",
          placeholder: "Select an operator",
          options: [
            { label: "Multiply", value: ConversionOperation.Multiply },
            { label: "Divide", value: ConversionOperation.Divide },
          ],
        })}
      />
      <NumericInput
        {...ctrl.props.NumericInput("conversionFactor", {
          name: "Conversion factor",
          placeholder: "Enter factor",
          min: 0,
        })}
      />
      <Input
        {...ctrl.props.Input("name", {
          name: "Conversion name",
          placeholder: "Optional name for the conversion",
        })}
      />
    </div>
  );
};
