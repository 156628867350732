import { GraphQLError } from "graphql";
import * as Sentry from "@sentry/react";
import { onError } from "@apollo/client/link/error";
import { useAuth } from "lib/auth";
import { get } from "lib/typeHelpers/object";
type AuthContext = ReturnType<typeof useAuth>;

const codesToIgnore = new Set<string>([
  // This list is incomplete, you can help by expanding it (by adding more codes that shouldn't be sent to Sentry).
  "BAD_USER_INPUT",
]);

// Constraint violations all have the same code, but we only want to ignore them if we believe they're handled.
const constraintViolationsToIgnore = new Set<string>([
  // This list is incomplete, you can help by expanding it (by adding more con that shouldn't be sent to Sentry).
  "CustomerIngestAlias_pkey",
]);

function logErrorToSentry(error: GraphQLError) {
  console.log("Sending error to sentry", error);
  const msg = `GraphQL Error: ${error.message}`;
  if (!Sentry.getCurrentHub().getClient()?.getOptions().enabled) {
    // If we're not reporting to Sentry (e.g. in dev environment), log
    // the error to the console.
    console.group(msg);
    console.dir(error);
    console.groupEnd();
  }
  Sentry.captureException(new Error(msg), (s) => {
    const fingerprint = [error.message];
    if (error.path) {
      const path = error.path.join(" > ");
      s.setTag("path", path);
      fingerprint.push(path);
    }
    if (error.extensions && error.extensions.code) {
      s.setTag("code", error.extensions.code);
      fingerprint.push(error.extensions.code);
    }
    s.setFingerprint(fingerprint);
    return s;
  });
}

export const createErrorLink = (auth: AuthContext) =>
  onError(({ graphQLErrors, networkError }) => {
    // If we get back an expired/invalid JWT the error message is sometimes buried outside of where the apollo types would expect.
    // Detect it, and then log the user out.
    const result = get(networkError, "result");
    const networkErrors = Array.isArray(result)
      ? result.flatMap((err: unknown) => get(err, "errors"))
      : [];

    const isBadJWT = networkErrors
      .concat(graphQLErrors)
      .find(
        (error: unknown) => get(error, "extensions", "code") === "invalid-jwt",
      );

    if (isBadJWT) {
      auth.logout();
      return;
    }

    // Often times an error being thrown in a federated service is returned as multiple errors to the client. The first one is the actual error,
    // and the subsequent ones are federation errors (cannot return null for non null field... etc).
    // If we find an error we want to ignore, we'll also ignore subsequent errors for that same path that we otherwise wouldn't.
    const pathsToIgnore = new Set<string>();

    for (const error of graphQLErrors || []) {
      if (codesToIgnore.has(error.extensions?.code)) {
        if (error.path) {
          pathsToIgnore.add(error.path.join(" > "));
        }
        continue;
      } else if (error.extensions?.code === "constraint-violation") {
        const constraintName = error.message.match(/"(.+)"$/)?.[1];
        if (
          constraintName &&
          constraintViolationsToIgnore.has(constraintName)
        ) {
          if (error.path) {
            pathsToIgnore.add(error.path.join(" > "));
          }
          continue;
        }
      }

      if (error.path && pathsToIgnore.has(error.path?.join(" > "))) {
        continue;
      }

      logErrorToSentry(error);
    }
  });
