import React from "react";

export interface DesignSystemConfig {
  routePathToHref: (routePath: string) => string;
}

const DEFAULT_CONFIG: DesignSystemConfig = {
  routePathToHref: (routePath) => {
    throw new Error(
      "DesignSystemConfigProvider must specify a routePathToHref() function",
    );
  },
};

const DesignSystemConfigContext =
  React.createContext<DesignSystemConfig>(DEFAULT_CONFIG);

export const DesignSystemConfigProvider: React.FC<
  React.PropsWithChildren<{
    config: Partial<DesignSystemConfig>;
  }>
> = ({ children, config }) => {
  const merged = React.useMemo(
    () => ({ ...DEFAULT_CONFIG, ...config }),
    [config],
  );

  return (
    <DesignSystemConfigContext.Provider value={merged}>
      {children}
    </DesignSystemConfigContext.Provider>
  );
};

export function useDesignSystemConfig() {
  return React.useContext(DesignSystemConfigContext);
}
