import React from "react";
import ReactSwitch from "react-switch";

import { twMerge } from "../../twMerge";
import { Label } from "../Typography";

import styles from "./index.module.css";
import { HelpCircleTooltip, Themes } from "../..";

type ControlProps = {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;

  compact?: boolean;

  label: React.ReactNode;
  // NB - this will not work if the CSS modules are already affecting that property
  className?: string;
};

type ToggleProps = ControlProps & {
  theme?: Themes;
  tooltip?: string;
};

export const Toggle: React.FC<ToggleProps> = (props) => {
  let onColor = "#046EEF"; // primary-600;
  switch (props.theme) {
    case "error":
      onColor = "#d62424"; // error-600
      break;
    case "warning":
      onColor = "#e89806"; // warning-500
      break;
    case "success":
      onColor = "#17a04e"; // success-600
      break;
  }

  return (
    <Label
      className={twMerge(
        styles.control,
        props.disabled ? styles.disabled : null,
        props.compact ? styles.compact : null,
        props.className,
      )}
    >
      <ReactSwitch
        className={styles.input}
        checked={props.checked}
        onChange={(checked) => props.onChange && props.onChange(checked)}
        disabled={!!props.disabled}
        height={16}
        width={28}
        handleDiameter={12}
        checkedIcon={false}
        uncheckedIcon={false}
        offColor="#AAB0B9" // Gray-300
        onColor={onColor}
      />
      {props.label}
      {props.tooltip && <HelpCircleTooltip content={props.tooltip} />}
    </Label>
  );
};

export const Checkbox: React.FC<ControlProps> = (props) => {
  return (
    <Label
      className={twMerge(
        styles.control,
        props.disabled ? styles.disabled : null,
        props.compact ? styles.compact : null,
        props.className,
      )}
    >
      <button
        type="button"
        aria-checked={props.checked}
        className={twMerge(
          styles.input,
          styles.checkbox,
          props.checked ? styles.checked : null,
        )}
        disabled={props.disabled}
        role="checkbox"
        onClick={() => props.onChange && props.onChange(!props.checked)}
      />
      {props.label}
    </Label>
  );
};

type RadioGroupProps = {
  value?: string;
  options: {
    value: string;
    label: string;
    disabled?: boolean;
  }[];
  onChange?: (value: string) => void;
};

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  return (
    <>
      {props.options.map((option) => {
        const checked = option.value === props.value;
        return (
          <Label
            key={option.value}
            className={twMerge(
              styles.control,
              option.disabled ? styles.disabled : null,
            )}
          >
            <button
              aria-checked={checked}
              className={twMerge(
                styles.input,
                styles.radio,
                checked ? styles.checked : null,
              )}
              onClick={() => props.onChange && props.onChange(option.value)}
              disabled={option.disabled}
              role="radio"
            >
              {checked && <div className={styles.radioDot} />}
            </button>
            {option.label}
          </Label>
        );
      })}
    </>
  );
};
