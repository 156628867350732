import React from "react";
import Gql from "graphql";
import { useApolloClient, DocumentNode } from "@apollo/client";

const name = (n: string): Gql.NameNode => ({
  kind: "Name",
  value: n,
});

const DEFAULT_REQ_STATE = Object.freeze({
  type: "loading",
});

type InfoFromInvoicesByIdReq<T> =
  | {
      type: "loading";
    }
  | {
      type: "error";
      error: unknown;
    }
  | {
      type: "success";
      data: Map<string, T>;
    };

/**
 * Fetch a Gql fragment for a list of invoice IDs in a single request. The `infoPerInvoice` fragment
 * should be an imported "*Document" value from a .graphql file, which will be used to define
 * the data fetched for each invoice. The type of this fragment's data should be passed as the generic
 * parameter `T` since graphql-let does not maintain type information for imported fragments.
 */
export function useInfoFromInvoicesById<T>(
  ids: string[],
  infoPerInvoice: DocumentNode,
  fragmentName: string,
) {
  const apollo = useApolloClient();
  const [req, setReq] =
    React.useState<InfoFromInvoicesByIdReq<T>>(DEFAULT_REQ_STATE);

  const fragment = infoPerInvoice.definitions.find(
    (def): def is Gql.FragmentDefinitionNode =>
      def.kind === "FragmentDefinition" && def.name.value === fragmentName,
  );

  if (!fragment) {
    throw new Error(
      `infoPerInvoice must be a documement containing a fragment definition named "${fragmentName}"`,
    );
  }

  React.useEffect(() => {
    const ctl = new AbortController();

    apollo
      .query({
        query: {
          kind: "Document",
          definitions: [
            ...infoPerInvoice.definitions,
            {
              kind: "OperationDefinition",
              operation: "query",
              name: name("InfoFromInvoicesById"),
              selectionSet: {
                kind: "SelectionSet",
                selections: ids.map(
                  (id, i): Gql.SelectionNode => ({
                    kind: "Field",
                    name: name("mri_invoice"),
                    alias: name(`i_${i}`),
                    arguments: [
                      {
                        kind: "Argument",
                        name: name("id"),
                        value: { kind: "StringValue", value: id },
                      },
                    ],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [
                        {
                          kind: "FragmentSpread",
                          name: name(fragment.name.value),
                        },
                      ],
                    },
                  }),
                ),
              },
            },
          ],
        },
      })
      .then(
        (result) => {
          if (ctl.signal.aborted) {
            return;
          }

          const data = new Map<string, T>();
          for (const [i, id] of ids.entries()) {
            data.set(id, result.data[`i_${i}`]);
          }

          setReq({
            type: "success",
            data: data,
          });
        },
        (error) => {
          if (ctl.signal.aborted) {
            return;
          }

          setReq({
            type: "error",
            error,
          });
        },
      );

    return () => ctl.abort();
  }, [Array.from(ids).sort().join(","), infoPerInvoice]);

  return req;
}
