import {
  ContractNameFragment,
  ContractRoutePathFragment,
  ContractStatusFragment,
  ContractActivityFragment,
  ContractResellerRoyaltiesFragment,
  ContractBillingProviderFragment,
  ContractTransitionsFragment,
} from "./fragments.graphql";

import { TransitionTypeEnum } from "types/generated-graphql/__types__";

import { ActivityItem } from "../../components/ActivityList";
import { printDate, toDayjs, Dayjs, distanceFrom } from "lib/date";
import { compact } from "../array";
import type { ContractOrPlan } from "../ContractOrPlan";
import { formatBillingProvider } from "../../../../lib/billingProvider/formatBillingProvider";

export type {
  ContractNameFragment as NameFragment,
  ContractRoutePathFragment as RoutePathFragment,
  ContractStatusFragment as StatusFragment,
  ContractActivityFragment as ActivityFragment,
  ContractBillingProviderFragment as BillingProviderFragment,
  ContractTransitionsFragment as TransitionFragment,
} from "./fragments.graphql";

export function makeName(
  startingAt: string,
  contractName: string | null,
  rateCard?: { name: string | null } | null,
): string {
  const date = printDate(toDayjs(startingAt));
  return contractName ?? `${rateCard?.name ?? "--"} (${date})`;
}

/**
 * Get the name of a contract. Defaults to the rate card name and includes the starting date of the contract
 */
export function getName(contract: ContractNameFragment): string {
  return makeName(contract.starting_at, contract.name, contract.rate_card);
}

/**
 * Get the "route path" for the contract, this path does not include the env type, that is
 * added automatically by elements that produce links.
 */
export function getRoutePath(contract: ContractRoutePathFragment): string {
  return `/customers/${contract.customer.id}/contracts/${contract.id}`;
}

/**
 * Get all the activity items for this contract.
 */
export function getActivityItems(
  contract: ContractActivityFragment,
  now: Dayjs,
  sortByRecency?: boolean,
): ActivityItem[] {
  const getEndString = () => {
    const transition = getTransition(contract);

    let endString = "Contract end";

    if (transition?.type === TransitionTypeEnum.Supersede) {
      endString += " (superseded)";
    } else if (transition?.type === TransitionTypeEnum.Renewal) {
      endString += " (renewed)";
    }

    return endString;
  };

  const startedId = "start";
  const endedId = "end";

  const activityItems = compact<ActivityItem>(
    {
      id: startedId,
      type: "start",
      displayType: "primary",
      time: toDayjs(contract.starting_at),
      routePath: `${getRoutePath(contract)}/lifecycle/${startedId}`,
      contractOrPlanName: getName(contract),
      content: "Contract start",
      createdAt: toDayjs(contract.created_at),
    },

    contract.amendments.map((a): ActivityItem => {
      return {
        id: a.id,
        type: "amendment",
        displayType: "primary",
        time: toDayjs(a.effective_at),
        routePath: `${getRoutePath(contract)}/lifecycle/${a.id}`,
        contractOrPlanName: getName(contract),
        content: "Contract amendment",
        createdAt: toDayjs(a.created_at),
      };
    }),

    contract.ending_before && now.isSameOrAfter(contract.ending_before)
      ? {
          id: endedId,
          type: "end",
          displayType: "warning",
          time: toDayjs(contract.ending_before),
          routePath: `${getRoutePath(contract)}/lifecycle/${endedId}`,
          contractOrPlanName: getName(contract),
          content: getEndString(),
          createdAt: toDayjs(contract.ending_before),
        }
      : null,
  );

  if (sortByRecency) {
    return activityItems.sort((a, b) => {
      const timeDiff = b.time.diff(a.time);
      if (
        timeDiff !== 0 ||
        a.createdAt === undefined ||
        b.createdAt === undefined
      ) {
        return timeDiff;
      }
      return b.createdAt.diff(a.createdAt);
    });
  }

  return activityItems;
}

/**
 * Describe the status of a contract at a specific time.
 */
export function getStatus(
  contract: ContractStatusFragment,
  now: Dayjs,
): ContractOrPlan.Status {
  if (contract.archived_at) {
    return "archived";
  }
  const start = distanceFrom(now, toDayjs(contract.starting_at));
  const end = contract.ending_before
    ? distanceFrom(now, toDayjs(contract.ending_before))
    : null;

  if (!start.isInPast) {
    return start.distDays >= 30 ? "upcoming" : "active-soon";
  }

  if (end?.isInPast) {
    const transitionOut = getTransition(contract);
    if (transitionOut !== undefined) {
      return transitionOut.type === TransitionTypeEnum.Supersede
        ? "inactive (superseded)"
        : "inactive (renewed)";
    } else {
      return end.distDays <= 7 ? "recently-ended" : "ended";
    }
  }

  return end && end?.distDays <= 30 ? "ending-soon" : "active";
}

export type ResellerRoyaltyState =
  ContractResellerRoyaltiesFragment["reseller_royalties"][0];

/**
 * Get the current state of a contract's reseller royalties.
 */
export function getResellerRoyaltiesStates(
  contract: ContractResellerRoyaltiesFragment,
  showAmendments: boolean,
  now: Dayjs,
): ResellerRoyaltyState[] {
  const royaltyMap = {
    AWSRoyaltyUpdate: "AWSRoyalty",
    GCPRoyaltyUpdate: "GCPRoyalty",
    AWSProServiceRoyaltyUpdate: "AWSProServiceRoyalty",
    GCPProServiceRoyaltyUpdate: "GCPProServiceRoyalty",
  };

  const current = new Map<string, ResellerRoyaltyState>(
    contract.reseller_royalties.map((r) => [r.__typename, r]),
  );

  const royalties = contract.reseller_royalties;
  for (const royalty of royalties) {
    current.set(royalty.__typename, royalty);
  }

  if (!showAmendments) {
    return Array.from(current.values());
  }

  for (const amendment of contract.amendments) {
    for (const royalty of amendment.reseller_royalties) {
      const startingAt =
        "updated_starting_at" in royalty
          ? royalty.updated_starting_at
          : "starting_at" in royalty
            ? royalty.starting_at
            : undefined;
      if (!startingAt || toDayjs(startingAt).isSameOrBefore(now)) {
        if (
          royalty.__typename === "AWSRoyalty" ||
          royalty.__typename === "GCPRoyalty" ||
          royalty.__typename === "AWSProServiceRoyalty" ||
          royalty.__typename === "GCPProServiceRoyalty"
        ) {
          current.set(royalty.__typename, royalty);
        } else {
          const resellerType = royaltyMap[royalty.__typename];
          const oldRoyalty = current.get(resellerType);
          if (!oldRoyalty) {
            throw new Error(
              `${royalty.__typename} has no existing ${resellerType} on contract ${contract.id}, amendment ${amendment.id}`,
            );
          }

          const baseNewRoyalty = {
            ...oldRoyalty,
            netsuite_reseller_id:
              royalty.updated_netsuite_reseller_id ??
              oldRoyalty.netsuite_reseller_id,
            fraction: royalty.updated_fraction ?? oldRoyalty.fraction,
            applicable_product_ids:
              royalty.updated_applicable_product_ids ??
              oldRoyalty.applicable_product_ids,
            starting_at: royalty.updated_starting_at ?? oldRoyalty.starting_at,
            ending_before:
              royalty.updated_ending_before ?? oldRoyalty.ending_before,
          };

          switch (royalty.__typename) {
            case "AWSRoyaltyUpdate":
              if (oldRoyalty.__typename !== "AWSRoyalty") {
                throw new Error(
                  `Expected AWSRoyalty, received ${baseNewRoyalty.__typename}`,
                );
              }
              current.set(resellerType, {
                ...baseNewRoyalty,
                __typename: "AWSRoyalty",
                aws_account_number:
                  royalty.updated_aws_account_number ??
                  oldRoyalty.aws_account_number,
                aws_payer_reference_id:
                  royalty.updated_aws_payer_reference_id ??
                  oldRoyalty.aws_payer_reference_id,
                aws_offer_id:
                  royalty.updated_aws_offer_id ?? oldRoyalty.aws_offer_id,
                applicable_product_tags:
                  royalty.updated_applicable_product_tags ??
                  oldRoyalty.applicable_product_tags,
              });
              break;

            case "AWSProServiceRoyaltyUpdate":
              if (oldRoyalty.__typename !== "AWSProServiceRoyalty") {
                throw new Error(
                  `Expected AWSProServiceRoyalty, received ${baseNewRoyalty.__typename}`,
                );
              }
              current.set(resellerType, {
                ...baseNewRoyalty,
                __typename: "AWSProServiceRoyalty",
                aws_account_number:
                  royalty.updated_aws_account_number ??
                  oldRoyalty.aws_account_number,
                aws_payer_reference_id:
                  royalty.updated_aws_payer_reference_id ??
                  oldRoyalty.aws_payer_reference_id,
                aws_offer_id:
                  royalty.updated_aws_offer_id ?? oldRoyalty.aws_offer_id,
              });
              break;

            case "GCPRoyaltyUpdate":
              if (oldRoyalty.__typename !== "GCPRoyalty") {
                throw new Error(
                  `Expected GCPRoyalty, received ${baseNewRoyalty.__typename}`,
                );
              }

              current.set(resellerType, {
                ...baseNewRoyalty,
                __typename: "GCPRoyalty",
                gcp_account_id:
                  royalty.updated_gcp_account_id ?? oldRoyalty.gcp_account_id,
                gcp_offer_id:
                  royalty.updated_gcp_offer_id ?? oldRoyalty.gcp_offer_id,
                applicable_product_tags:
                  royalty.updated_applicable_product_tags ??
                  oldRoyalty.applicable_product_tags,
              });
              break;

            case "GCPProServiceRoyaltyUpdate":
              if (oldRoyalty.__typename !== "GCPProServiceRoyalty") {
                throw new Error(
                  `Expected GCPProServiceRoyalty, received ${baseNewRoyalty.__typename}`,
                );
              }
              current.set(resellerType, {
                ...baseNewRoyalty,
                __typename: "GCPProServiceRoyalty",
                gcp_account_id:
                  royalty.updated_gcp_account_id ?? oldRoyalty.gcp_account_id,
                gcp_offer_id:
                  royalty.updated_gcp_offer_id ?? oldRoyalty.gcp_offer_id,
              });
              break;

            default:
              royalty satisfies never;
              throw new Error(`Unexpected royalty type ${royalty}`);
          }
        }
      }
    }
  }

  return Array.from(current.values());
}

export function getBillingProvider(contract: ContractBillingProviderFragment) {
  return formatBillingProvider(
    contract?.customer_billing_provider_configuration?.billing_provider,
  );
}

export function getTransition(contract: ContractTransitionsFragment) {
  return contract.transitions.find(
    (t) => t.from_contract.id === contract.id && !t.to_contract.archived_at,
  );
}
