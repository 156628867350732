export function compact<T>(
  ...arr: Array<T | undefined | null | Array<T | undefined | null>>
): T[] {
  return arr.flat().filter((i): i is T => i != null);
}

export function uniq<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function isArrayOfStrings(v: unknown): v is string[] {
  return Array.isArray(v) && v.every((e) => typeof e === "string");
}

export function intersperse<T>(arr: T[], sep: T): T[] {
  return arr.flatMap((e, i) => (i === 0 ? [e] : [sep, e]));
}
