import React, { useState, useEffect, useContext } from "react";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { useNavigate } from "react-router-dom";
import { ImpersonationApprovalScreen } from "./ImpersonationApprovalScreen";
import { useGetUserQuery } from "./queries.graphql";
import { UserAuthTypeEnum_Enum } from "types/generated-graphql/__types__";

const IMPERSONATION_TOKEN_KEY = "impersonationToken";

type ImpersonationInfo = {
  expires: Date;
  name: string;
  email: string;
  role: string;
  client_id: string;
};

type UserState = {
  userID: string;
  impersonationInfo?: ImpersonationInfo;
};

// This function naively parses a JWT into a Metronome user. If the format doesn't match the expected format
// it will bail.
// **IMPORTANT** - This function does no validation of the signature, which means anyone can create a JWT it will
// accept. The only reason this is acceptable is that the backend validates tokens, so we can blindly trust the
// user in the token since if the token is forged the backend will not accept it.
function unsafeParseJWT(token: string): UserState | null {
  try {
    const [, encodedClaims] = token.split(".");
    const claims = JSON.parse(atob(encodedClaims));
    const hasuraClaims = claims["https://hasura.io/jwt/claims"];
    const role = hasuraClaims?.["x-hasura-rbac-role"] ?? "admin";
    const user = claims["https://metronome.com"].user;
    return {
      userID: user.id,
      impersonationInfo: {
        email: user.email,
        name: user.name,
        role,
        expires: new Date(claims.exp * 1000),
        client_id: user.client_id,
      },
    };
  } catch {
    return null;
  }
}

let globalTokenCache: {
  [auth0token: string]: { [environment: string]: undefined | Promise<string> };
} = {};

export type AuthContext = {
  userID: string;
  impersonationInfo?: ImpersonationInfo;
  getAccessToken: (environment: string | null) => Promise<string>;
  logout: () => void;
};

const AuthContext = React.createContext<AuthContext>(null as any);

type ImpersonationState = null | {
  token: string;
  approvedByUser: boolean;
};

type AuthProviderProps = React.PropsWithChildren<{
  domain: string;
  clientID: string;
  redirectURI: string;
  gatekeeperURL: string;
}>;

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  domain,
  clientID,
  redirectURI,
  gatekeeperURL,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserState>();
  const [impersonationState, setImpersonationState] =
    useState<ImpersonationState>(null);
  const [auth0Client, setAuth0] = useState<Auth0Client>();

  useEffect(() => {
    void (async () => {
      const auth0Client = await createAuth0Client({
        domain,
        client_id: clientID,
        redirect_uri: redirectURI,
        audience: "graphql",
        localstorage: "localstorage",
      });
      setAuth0(auth0Client);

      // First and foremost, if the user has approved that they're trying to impersonate (which means they reconize the email
      // and are trying to log in to the account) then we can set the user to the user we extracted from the JWT. Note that
      // no verification of the JWT is done, so an attacker can forge a JWT at this point. Once it's actually used against the
      // backend it will fail though.
      if (impersonationState?.approvedByUser) {
        const impersonatedUser = unsafeParseJWT(impersonationState.token);
        if (impersonatedUser) {
          console.log("Setting user", impersonatedUser);
          setUser(impersonatedUser);
          window.localStorage[IMPERSONATION_TOKEN_KEY] =
            impersonationState.token;
          return;
        }
      }

      // If the url contains an impersonation token, then we can kick off the impersonation process. At this point,
      // someone has linked to the app with a token. We've neither verified the token nor verified the intent of the
      // person linking. An attacker could attempt to trick a user into taking sensitive actions in the wrong account
      // by sending them a covert link with the attacker's (valid) token for example. As such we now need to prompt
      // for user approval, so the user has to explicitly confirm they're attempting to impersonate as the given user.
      if (window.location.search.includes("impersonationToken=")) {
        const token =
          new URLSearchParams(window.location.search).get(
            "impersonationToken",
          ) || "";
        setImpersonationState({
          approvedByUser: false,
          token,
        });
        return;
      }

      if (window.localStorage[IMPERSONATION_TOKEN_KEY]) {
        const token = window.localStorage.impersonationToken;
        const impersonatedUser = unsafeParseJWT(token);
        if (impersonatedUser && impersonatedUser.impersonationInfo) {
          if (impersonatedUser.impersonationInfo?.expires <= new Date()) {
            window.localStorage.removeItem(IMPERSONATION_TOKEN_KEY);
          } else {
            setImpersonationState({
              approvedByUser: true,
              token,
            });
            return;
          }
        }
      }

      // If the url contains code & state then it's a normal login flow completing, and we can call auth0 to handle and
      // direct the user back to wherever they were trying to go.
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0Client.handleRedirectCallback();
        if (appState && appState.targetUrl) {
          navigate(appState.targetUrl, { replace: true });
        }
      }

      if (await auth0Client.isAuthenticated()) {
        const user = await auth0Client.getUser();
        if (!user?.sub) {
          throw new Error("No auth0 user sub found");
        }
        setUser({
          userID: user.sub,
        });
      } else {
        return auth0Client.loginWithRedirect({
          appState: {
            targetUrl: `${window.location.pathname}${window.location.search}`,
          },
        });
      }
    })();
  }, [impersonationState?.token, impersonationState?.approvedByUser]);

  if (impersonationState && !impersonationState.approvedByUser) {
    const user = unsafeParseJWT(impersonationState.token);
    if (!user || !user.impersonationInfo) {
      void auth0Client?.logout();
      return null;
    }

    // Note - at this point email could be forged, but if that was the case the token will not work once the user "approves"
    // the interstatial screen.
    return (
      <ImpersonationApprovalScreen
        email={user.impersonationInfo?.email}
        onDeny={() => void auth0Client?.logout()}
        onApprove={() => {
          setImpersonationState({
            approvedByUser: true,
            token: impersonationState.token,
          });
          // Clear out any query parameters
          if (window.location.search.length > 0) {
            navigate(location.pathname, { replace: true });
          }
        }}
      />
    );
  }

  if (!auth0Client) {
    return null;
  }

  if (!user) {
    return null;
  }

  const getAccessToken = async (
    environment: string | null,
  ): Promise<string> => {
    if (!environment) {
      if (impersonationState?.approvedByUser) {
        return impersonationState.token;
      }
      return auth0Client.getTokenSilently();
    }

    const unscopedToken = await getAccessToken(null);
    if (!globalTokenCache[unscopedToken]) {
      globalTokenCache[unscopedToken] = {};
    }
    const cachedValue = globalTokenCache[unscopedToken][environment];
    if (cachedValue) {
      return cachedValue;
    }
    // Cache the promise so we don't make multiple requests for the same token
    return (globalTokenCache[unscopedToken][environment] = (async () => {
      const request = await fetch(`${gatekeeperURL}/reissue-jwt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${unscopedToken}`,
        },
        body: JSON.stringify({ environment }),
      });
      const { token } = await request.json();
      return token;
    })());
  };

  return (
    <AuthContext.Provider
      value={{
        userID: user.userID,
        getAccessToken,
        logout: () => {
          window.localStorage.removeItem("impersonationToken");
          globalTokenCache = {};
          void auth0Client.logout({
            returnTo: redirectURI,
          });
        },
        impersonationInfo: impersonationState?.approvedByUser
          ? user.impersonationInfo
          : undefined,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

type UseAuthValue = {
  getAccessToken: (environment: string | null) => Promise<string>;
  logout: () => void;
  userID: string;
} & (
  | {
      impersonated: false;
    }
  | {
      impersonated: true;
      impersonationSessionExpires: Date;
    }
);

export const useAuth = (): UseAuthValue => {
  const { userID, logout, impersonationInfo, getAccessToken } =
    useContext(AuthContext);

  if (impersonationInfo) {
    return {
      impersonated: true as const,
      impersonationSessionExpires: impersonationInfo.expires,
      logout,
      getAccessToken,
      userID,
    };
  }

  return {
    impersonated: false,
    logout,
    getAccessToken,
    userID,
  };
};

export const useCurrentUser = () => {
  const { userID, impersonated } = useAuth();
  const { impersonationInfo } = useContext(AuthContext);
  const { loading, data, error } = useGetUserQuery({
    skip: !!(impersonated && impersonationInfo),
  });

  if (impersonated && impersonationInfo) {
    return {
      loading: false,
      user: {
        id: userID,
        name: impersonationInfo.name,
        email: impersonationInfo.email,
        auth_type: UserAuthTypeEnum_Enum.Password,
        role: impersonationInfo.role,
      },
      clientID: impersonationInfo.client_id,
      isMetronomeAdmin: true,
    };
  }

  if (!loading && error) {
    return {
      loading: false as const,
      user: undefined,
      error,
      clientID: undefined,
      isMetronomeAdmin: impersonated,
    };
  }

  if (loading || !data?.me) {
    return {
      loading: true as const,
      user: undefined,
      clientID: undefined,
      isMetronomeAdmin: impersonated,
    };
  }

  return {
    loading: false as const,
    user: data.me,
    clientID: data.me.client_id,
    isMetronomeAdmin: data.me.email.endsWith("@metronome.com"),
  };
};
