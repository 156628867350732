import React from "react";
import { Headline } from "design-system";

function LandingPage() {
  return (
    <Headline level={2} className="m-12 text-center">
      Metronome embeddable dashboards
    </Headline>
  );
}

export default LandingPage;
