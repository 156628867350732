import {
  ChargeTypeEnum_Enum,
  CompositeChargeTypeEnum_Enum,
  CreditTypeConversionInput,
  CustomPricingInput,
  PriceAdjustmentTypeEnum_Enum,
} from "types/generated-graphql/__types__";
import Decimal from "decimal.js";
import { CustomPricingDetailsFragment } from "./customPricing.graphql";
import { PlanQuery } from "../plans/queries.graphql";
import { CreditTypeConversion } from "lib/plans/types";

export type PriceAdjustment = {
  /* Decimal is for entered values, null is for 0 values (e.g. 0.000) and undefined is for reseting the credit input */
  value: Decimal | null | undefined;
  adjustment_type_enum: string;
  metric_minimum?: number;
  composite_minimum?: number;
  quantity?: Decimal | null | undefined;
  charge_type_enum: ChargeTypeEnum_Enum | null;
};

type PricedProductPricingFactorAdjustment = {
  priced_product_id: string;
  product_pricing_factor_id: string;
  charge_type_enum: ChargeTypeEnum_Enum | null;
  price_adjustments: PriceAdjustment[];
  start_period: number;
};

export type CustomPricing = {
  pricedProductPricingFactorAdjustments: PricedProductPricingFactorAdjustment[];
};

export const clientAdjTypes = {
  NONE: "",
  OVERRIDE: "OVERRIDE",
  PERCENTAGE_OVERRIDE: "PERCENTAGE_OVERRIDE",
  PERCENTAGE_INCREASE: "PERCENTAGE_INCREASE",
  PERCENTAGE_DECREASE: "PERCENTAGE_DECREASE",
  FIXED_INCREASE: "FIXED_INCREASE",
  FIXED_DECREASE: "FIXED_DECREASE",
  QUANTITY_ONLY: "QUANTITY_ONLY",
};

export const getPriceAdjustment = (
  pricedProductId: string | undefined,
  productPricingFactorId: string | undefined,
  startPeriod: number,
  customPricing: CustomPricing,
  metricMinimum?: number,
): PriceAdjustment | undefined => {
  if (!pricedProductId || !productPricingFactorId) {
    return undefined;
  }

  const pppfaList = customPricing.pricedProductPricingFactorAdjustments;
  const pppfa = pppfaList.find((pppfa) => {
    return (
      pppfa.start_period === startPeriod &&
      pppfa.priced_product_id === pricedProductId &&
      pppfa.product_pricing_factor_id === productPricingFactorId
    );
  });

  if (pppfa) {
    return pppfa.price_adjustments.find((pa) => {
      if (metricMinimum !== undefined) {
        return metricMinimum === pa.metric_minimum;
      }
      /* If there is no metric minimum it will be a flat fee and there can only be one */
      return true;
    });
  }
  return undefined;
};

function getCompositeChargeTypeForAdjustment(
  adjustmentProductPricingFactorId: string,
  adjustmentStartPeriod: string,
  pricedProduct?: CustomPricingDetailsFragment["PricedProductPricingFactorAdjustments"][0]["PricedProduct"],
): CompositeChargeTypeEnum_Enum | undefined {
  const pricedProductPricingFactor =
    pricedProduct?.PricedProductPricingFactors.find(
      (pppf) =>
        pppf.ProductPricingFactor.id === adjustmentProductPricingFactorId &&
        pppf.start_period === adjustmentStartPeriod,
    );

  return pricedProductPricingFactor?.CompositeCharges?.[0]?.type;
}

/* Create, update, delete custom pricing price adjustments */
export const updateCustomPricing = (
  pricedProductId: string,
  productPricingFactorId: string,
  startPeriod: number,
  customPricing: CustomPricing,
  metricMinimum?: number,
  priceAdjustment?: PriceAdjustment,
): CustomPricing => {
  const pppfaList = customPricing.pricedProductPricingFactorAdjustments;
  const pppfaIndex = pppfaList.findIndex((pppfa) => {
    return (
      pppfa.start_period === startPeriod &&
      pppfa.priced_product_id === pricedProductId &&
      pppfa.product_pricing_factor_id === productPricingFactorId
    );
  });
  let pppfa: PricedProductPricingFactorAdjustment;

  /* If there is a PPPFA that means we are updating an existing price adjustment */
  if (pppfaIndex >= 0) {
    /* Create a copy of the pppfa */
    pppfa = {
      ...pppfaList[pppfaIndex],
      price_adjustments: [...pppfaList[pppfaIndex].price_adjustments],
    };
    const paList = pppfa.price_adjustments;
    /* If there is no price adjustment delete the matching adjustment */
    if (!priceAdjustment) {
      /* Usage based */
      if (metricMinimum !== undefined) {
        const paIndex = paList.findIndex((pa) => {
          return pa.metric_minimum === metricMinimum;
        });
        /* If we can find the price adjustment remove it */
        if (paIndex >= 0) {
          pppfa.price_adjustments = [
            ...paList.slice(0, paIndex),
            ...paList.slice(paIndex + 1),
          ];
        }
        /* Flat fee */
      } else {
        pppfa.price_adjustments = [];
      }
      /* Flat fees and composite charges can not be tiered so there can only be one */
    } else if (priceAdjustment.quantity !== undefined) {
      if (pppfa.charge_type_enum === ChargeTypeEnum_Enum.Usage) {
        throw new Error(
          "A usage based price adjustment can not have a quantity",
        );
      }

      /* Update the flat fee/composite charge adjustment */
      if (pppfa.price_adjustments[0]) {
        pppfa.price_adjustments = [
          {
            ...pppfa.price_adjustments[0],
            ...priceAdjustment,
            quantity: priceAdjustment.quantity,
          },
        ];
        /* Create a new flat fee/composite charge adjustment */
      } else {
        pppfa.price_adjustments.push(priceAdjustment);
      }

      /* If it's not a flat fee/composite charge, it's usage based */
    } else {
      const paIndex = paList.findIndex((pa) => {
        return pa.metric_minimum === priceAdjustment.metric_minimum;
      });
      /* Update the usage based adjustment */
      if (paIndex >= 0) {
        pppfa.price_adjustments = [
          ...paList.slice(0, paIndex),
          {
            ...paList[paIndex],
            ...priceAdjustment,
          },
          ...paList.slice(paIndex + 1),
        ];
        /* Create the usage based adjustment */
      } else {
        pppfa.price_adjustments.push(priceAdjustment);
      }
    }
    /* There is no PPPFA so we need to create a new price adjustment and PPPFA */
  } else {
    if (priceAdjustment) {
      pppfa = {
        priced_product_id: pricedProductId,
        product_pricing_factor_id: productPricingFactorId,
        start_period: startPeriod,
        price_adjustments: [priceAdjustment],
        charge_type_enum: priceAdjustment.charge_type_enum,
      };
    } else {
      return customPricing;
    }
  }

  const deletePppfa = !priceAdjustment && pppfa.price_adjustments.length === 0;
  /* If we have an index ensure we add it in the correct spot or potentially delete it */
  if (pppfaIndex >= 0) {
    return {
      ...customPricing,
      pricedProductPricingFactorAdjustments: [
        ...pppfaList.slice(0, pppfaIndex),
        ...(deletePppfa ? [] : [pppfa]),
        ...pppfaList.slice(pppfaIndex + 1),
      ],
    };
  } else {
    /* If we don't have an index it must be net new */
    return {
      ...customPricing,
      pricedProductPricingFactorAdjustments: pppfaList.concat([pppfa]),
    };
  }
};

export function validatePPPFAQuantity(
  quantity: Decimal | null | undefined,
  isCompositeCharge: boolean,
) {
  if (quantity === undefined) {
    return true;
  } else if (isCompositeCharge) {
    return quantity?.eq(0) || quantity?.eq(1);
  } else {
    return quantity?.greaterThanOrEqualTo(0);
  }
}

const isCustomProductPricingValid = (
  plan: PlanQuery | null,
  customPricing: CustomPricing,
): boolean => {
  if (!plan) {
    return false;
  }
  /* We expect at least one PPPFA and price adjustment to be added */
  if (customPricing.pricedProductPricingFactorAdjustments.length) {
    return customPricing.pricedProductPricingFactorAdjustments.every(
      (pppfa) => {
        /*
            - Usage-based       price adjustments have a non-null value
            - Flat fee          price adjustments have a non-null value, positive quantity
            - Composite (pct)   price adjustments have a non-null positive quantity, optional positive value
            - Composite (min)   price adjustments have a quantity of 0 or 1, optional positive value
            - No PPPFAs have no price adjustments
          */

        return (
          pppfa.price_adjustments.length > 0 &&
          pppfa.price_adjustments.every(
            (pa) =>
              !!pa.value &&
              validatePPPFAQuantity(
                pa.quantity,
                pppfa.charge_type_enum === ChargeTypeEnum_Enum.Composite,
              ),
          )
        );
      },
    );
  }
  return true;
};

const isCreditTypeConversionAdjustmentsValid = (
  plan: PlanQuery | null,
  creditTypeConversionAdjustments: CreditTypeConversion[] | undefined,
): boolean => {
  if (!plan) {
    return false;
  }
  if (!creditTypeConversionAdjustments) {
    return true;
  }
  return creditTypeConversionAdjustments.every(
    (adjustment) => adjustment.toFiatConversionFactor != undefined,
  );
};

export const isCustomPricingValid = (
  plan: PlanQuery | null,
  customPricing: CustomPricing,
  creditTypeConversionAdjustments: CreditTypeConversion[] | undefined,
): boolean => {
  const atLeastOnePopulated =
    customPricing.pricedProductPricingFactorAdjustments.length > 0 ||
    (creditTypeConversionAdjustments !== undefined &&
      creditTypeConversionAdjustments.length > 0);
  if (!atLeastOnePopulated) {
    return false;
  }
  return (
    isCustomProductPricingValid(plan, customPricing) &&
    isCreditTypeConversionAdjustmentsValid(
      plan,
      creditTypeConversionAdjustments,
    )
  );
};

const valueToEnumMap = (
  value: string,
): [PriceAdjustmentTypeEnum_Enum, Decimal] => {
  switch (value) {
    case clientAdjTypes.OVERRIDE:
      return [PriceAdjustmentTypeEnum_Enum.RateOverride, new Decimal(1)];
    case clientAdjTypes.PERCENTAGE_OVERRIDE:
      return [PriceAdjustmentTypeEnum_Enum.RateOverride, new Decimal(0.01)];
    case clientAdjTypes.PERCENTAGE_INCREASE:
      return [PriceAdjustmentTypeEnum_Enum.Percentage, new Decimal(0.01)];
    case clientAdjTypes.PERCENTAGE_DECREASE:
      return [PriceAdjustmentTypeEnum_Enum.Percentage, new Decimal(-0.01)];
    case clientAdjTypes.FIXED_INCREASE:
      return [PriceAdjustmentTypeEnum_Enum.FixedAmount, new Decimal(1)];
    case clientAdjTypes.FIXED_DECREASE:
      return [PriceAdjustmentTypeEnum_Enum.FixedAmount, new Decimal(-1)];
    case clientAdjTypes.QUANTITY_ONLY:
      return [PriceAdjustmentTypeEnum_Enum.Quantity, new Decimal(1)];
    default:
      throw new Error("Unknown price adjustment type");
  }
};

/* Serialize custom pricing to be sent to data layer */
export const serializeCustomPricing = (
  customPricing: CustomPricing | undefined,
): CustomPricingInput | undefined => {
  if (!customPricing) {
    return undefined;
  }

  return {
    priced_product_pricing_factor_adjustments:
      customPricing.pricedProductPricingFactorAdjustments.map((pppfa) => {
        if (!pppfa.charge_type_enum) {
          throw new Error(
            "Priced product pricing factor adjustment must have a non-null charge_type_enum",
          );
        }
        return {
          priced_product_id: pppfa.priced_product_id,
          product_pricing_factor_id: pppfa.product_pricing_factor_id,
          charge_type_enum: pppfa.charge_type_enum,

          start_period: String(pppfa.start_period),
          price_adjustments: pppfa.price_adjustments.flatMap((pa) => {
            const isCompositeChargeQuantityOnlyAdjustment =
              pa.charge_type_enum === ChargeTypeEnum_Enum.Composite &&
              pa.adjustment_type_enum === clientAdjTypes.QUANTITY_ONLY;
            if (!pa.value && !isCompositeChargeQuantityOnlyAdjustment) {
              /* If there is no value for a Price Adjustment don't create it */
              return [];
            }
            const [adjustmentType, multiplier] = valueToEnumMap(
              pa.adjustment_type_enum,
            );
            return [
              {
                value: !isCompositeChargeQuantityOnlyAdjustment
                  ? pa.value?.mul(multiplier).toString()
                  : undefined,
                metric_minimum: pa.metric_minimum?.toString(),
                composite_minimum: !isCompositeChargeQuantityOnlyAdjustment
                  ? pa.composite_minimum?.toString()
                  : undefined,
                quantity: pa.quantity?.toString(),
                adjustment_type_enum: adjustmentType,
              },
            ];
          }),
        };
      }),
  };
};

export const serializeCreditTypeConversionAdjustments = (
  adjustments: CreditTypeConversion[] | undefined,
): CreditTypeConversionInput[] | undefined => {
  if (!adjustments) {
    return undefined;
  }
  return adjustments.map((adjustment) => {
    if (adjustment.toFiatConversionFactor === undefined) {
      throw new Error(
        "Adjustment must have non-empty to_fiat_conversion_factor",
      );
    }
    return {
      start_period: new Decimal(adjustment.startPeriod).toString(),
      custom_credit_type_id: adjustment.customCreditType.id,
      fiat_currency_credit_type_id: adjustment.fiatCreditType.id,
      to_fiat_conversion_factor: new Decimal(
        adjustment.toFiatConversionFactor,
      ).toString(),
    };
  });
};

const enumToValueMap = (
  value: string,
  priceAdjustmentEnum: PriceAdjustmentTypeEnum_Enum,
  chargeType: ChargeTypeEnum_Enum,
  compositeChargeType?: CompositeChargeTypeEnum_Enum,
): [string, Decimal] => {
  switch (priceAdjustmentEnum) {
    case PriceAdjustmentTypeEnum_Enum.RateOverride:
      if (
        chargeType === ChargeTypeEnum_Enum.Composite &&
        compositeChargeType === CompositeChargeTypeEnum_Enum.Percentage
      ) {
        return [
          clientAdjTypes.PERCENTAGE_OVERRIDE,
          new Decimal(value).mul(100),
        ];
      }
      return [clientAdjTypes.OVERRIDE, new Decimal(value)];
    case PriceAdjustmentTypeEnum_Enum.Percentage:
      const percentValue = new Decimal(value);
      if (percentValue.toNumber() >= 0) {
        return [
          clientAdjTypes.PERCENTAGE_INCREASE,
          new Decimal(value).mul(100),
        ];
      }
      return [
        clientAdjTypes.PERCENTAGE_DECREASE,
        new Decimal(value).mul(100).abs(),
      ];
    case PriceAdjustmentTypeEnum_Enum.FixedAmount:
      const fixedValue = new Decimal(value);
      if (fixedValue.toNumber() >= 0) {
        return [clientAdjTypes.FIXED_INCREASE, new Decimal(value)];
      }
      return [clientAdjTypes.FIXED_DECREASE, new Decimal(value).abs()];
    case PriceAdjustmentTypeEnum_Enum.Quantity:
      return [clientAdjTypes.QUANTITY_ONLY, new Decimal(value)];
    default:
      throw new Error("Unknown price adjustment");
  }
};

/* Unserialize custom pricing from the data layer */
export const unserializeCustomPricing = (
  customPricing: CustomPricingDetailsFragment | undefined,
): CustomPricing | undefined => {
  if (!customPricing) {
    return undefined;
  }

  return {
    pricedProductPricingFactorAdjustments:
      customPricing.PricedProductPricingFactorAdjustments.map((pppfa) => {
        const chargeTypeEnum = pppfa.FlatFeeAdjustments?.length
          ? ChargeTypeEnum_Enum.Flat
          : pppfa.CompositeChargeAdjustments?.length
            ? ChargeTypeEnum_Enum.Composite
            : ChargeTypeEnum_Enum.Usage;
        let price_adjustments: CustomPricing["pricedProductPricingFactorAdjustments"][0]["price_adjustments"];

        if (chargeTypeEnum === ChargeTypeEnum_Enum.Flat) {
          const [adjustmentType, value] = enumToValueMap(
            pppfa.FlatFeeAdjustments[0].value,
            pppfa.FlatFeeAdjustments[0].adjustment_type_enum,
            chargeTypeEnum,
          );
          price_adjustments = [
            {
              value: value,
              adjustment_type_enum: adjustmentType,
              quantity: new Decimal(pppfa.FlatFeeAdjustments[0].quantity),
              charge_type_enum: chargeTypeEnum,
            },
          ];
        } else if (chargeTypeEnum === ChargeTypeEnum_Enum.Composite) {
          const compositeChargeAdjustment =
            pppfa.CompositeChargeAdjustments?.[0];
          const compositeChargeTierAdjustments =
            compositeChargeAdjustment?.CompositeChargeTierAdjustments;

          const compositeChargeType = getCompositeChargeTypeForAdjustment(
            pppfa.product_pricing_factor_id,
            pppfa.start_period,
            pppfa.PricedProduct,
          );

          price_adjustments = compositeChargeTierAdjustments?.length
            ? compositeChargeTierAdjustments.map((pa) => {
                const [adjustmentType, value] = enumToValueMap(
                  pa.value,
                  PriceAdjustmentTypeEnum_Enum.RateOverride,
                  chargeTypeEnum,
                  compositeChargeType,
                );
                return {
                  value: value,
                  adjustment_type_enum: adjustmentType,
                  quantity: new Decimal(
                    pppfa.CompositeChargeAdjustments[0].quantity,
                  ),
                  composite_minimum: Number(pa.composite_minimum),
                  charge_type_enum: chargeTypeEnum,
                };
              })
            : [
                {
                  value: new Decimal(0),
                  quantity: new Decimal(
                    pppfa.CompositeChargeAdjustments[0].quantity,
                  ),
                  adjustment_type_enum: clientAdjTypes.QUANTITY_ONLY,
                  charge_type_enum: chargeTypeEnum,
                },
              ];
        } else if (chargeTypeEnum === ChargeTypeEnum_Enum.Usage) {
          price_adjustments = pppfa.PriceAdjustments.map((pa) => {
            const [adjustmentType, value] = enumToValueMap(
              pa.value,
              pa.adjustment_type_enum,
              chargeTypeEnum,
            );
            return {
              value: value,
              adjustment_type_enum: adjustmentType,
              quantity: pa.quantity ? new Decimal(pa.quantity) : undefined,
              metric_minimum: Number(pa.metric_minimum),
              charge_type_enum: chargeTypeEnum,
            };
          });
        } else {
          throw new Error(
            `Invalid ChargeTypeEnum. Received: ${chargeTypeEnum}`,
          );
        }
        return {
          priced_product_id: pppfa.priced_product_id,
          product_pricing_factor_id: pppfa.product_pricing_factor_id,
          start_period: Number(pppfa.start_period),
          charge_type_enum: chargeTypeEnum,
          price_adjustments,
        };
      }),
  };
};
export const calculateCustomPrice = (
  value: Decimal,
  type: string,
  listPrice: Decimal,
) => {
  let customPrice: Decimal;

  switch (type) {
    case clientAdjTypes.OVERRIDE:
      customPrice = value;
      break;
    case clientAdjTypes.PERCENTAGE_OVERRIDE:
      customPrice = value;
      break;
    case clientAdjTypes.PERCENTAGE_INCREASE:
      customPrice = value.div(100).add(1).mul(listPrice);
      break;
    case clientAdjTypes.PERCENTAGE_DECREASE:
      customPrice = value.div(100).mul(-1).add(1).mul(listPrice);
      break;
    case clientAdjTypes.FIXED_INCREASE:
      customPrice = value.add(listPrice);
      break;
    case clientAdjTypes.FIXED_DECREASE:
      customPrice = value.mul(-1).add(listPrice);
      break;
    case clientAdjTypes.QUANTITY_ONLY:
      customPrice = listPrice;
      break;
    default:
      throw new Error("Unknown price adjustment");
  }
  /* Can't have a negative new price */
  if (customPrice.toNumber() < 0) {
    return new Decimal(0);
  }
  return customPrice;
};
