import { useParams } from "react-router-dom";

export function useOptionalParam<T extends string>(paramName: T) {
  const params: { [key in T]?: string } = useParams();
  return params[paramName];
}

export function useRequiredParam<T extends string>(paramName: T): string {
  const params: { [key in T]?: string } = useParams();
  const param = params[paramName];

  if (!param) {
    throw new Error(`Missing required param: ${paramName}`);
  }

  return param;
}

export function useRequiredParams<T extends string>(
  ...paramNames: T[]
): { [key in T]: string } {
  const params: { [key in T]?: string } = useParams();

  const missingParams = paramNames.filter((paramName) => !params[paramName]);
  if (missingParams.length > 0) {
    throw new Error(`Missing required params: ${missingParams.join(", ")}`);
  }

  return params as { [key in T]: string };
}
