import React from "react";
import { Badge, BadgeProps } from "design-system";

export type PricingGroupValues = Record<string, string> | null;

export namespace PricingGroupValues {
  export interface BadgesProps {
    type?: BadgeProps["type"];
    theme?: BadgeProps["theme"];
    pricingGroupValues: PricingGroupValues;
  }

  export const Badges: React.FC<BadgesProps> = ({
    pricingGroupValues,
    theme = "grey",
    type = "light",
  }) => {
    if (!pricingGroupValues) {
      return null;
    }

    const entries = Object.entries(pricingGroupValues);
    if (!entries.length) {
      return null;
    }

    return (
      <>
        {entries.map(([key, value]) => {
          return (
            <Badge key={key} type={type} theme={theme}>
              {key}: {value}
            </Badge>
          );
        })}
      </>
    );
  };
}
