import React, { useEffect, useState } from "react";
import { CreditType } from "types/credit-types";
import {
  displayCreditTypeName,
  RoundedCurrency,
  USD_CREDIT_TYPE,
} from "lib/credits";
import classNames from "classnames";
import { Subtitle, Select, Caption, Body } from "design-system";
import styles from "./index.module.less";
import Decimal from "decimal.js";
import { Ledger } from "../../types";

type CreditMetricProps = {
  isPrimary: boolean;
  label: string;
  amount: Decimal;
  creditType: CreditType;
};
const CreditMetric: React.FC<CreditMetricProps> = ({
  isPrimary,
  label,
  amount,
  creditType,
}) => {
  return (
    <div
      className={
        isPrimary
          ? classNames("mt mr-24", styles.primaryCreditInfo)
          : classNames("mr-12 mt-[6px]", styles.secondaryCreditInfo)
      }
    >
      <Caption
        level={1}
        className={classNames("mb-4 normal-case", styles.caption)}
      >
        {label}
      </Caption>
      <span
        className={classNames(
          "mr-4 inline-block font-medium text-default-font",
          isPrimary ? "text-xl leading-4" : "text-base leading-3",
          styles.amount,
        )}
      >
        <RoundedCurrency creditType={creditType} amount={amount} hideSuffix />
      </span>
      <Body
        level={2}
        className={classNames("mb-0 inline-block", styles.creditType)}
      >
        {displayCreditTypeName(creditType)}
      </Body>
    </div>
  );
};

type CreditTypeSelectProps = {
  creditTypes: CreditType[];
  selectedCreditType: CreditType;
  onChange: (creditType: CreditType) => void;
};
const CreditTypeSelect: React.FC<CreditTypeSelectProps> = ({
  creditTypes,
  selectedCreditType,
  onChange,
}) => {
  const [selectedCreditTypeId, setSelectedCreditTypeId] = useState<string>(
    selectedCreditType.id,
  );

  useEffect(
    () => setSelectedCreditTypeId(selectedCreditType.id),
    [selectedCreditType],
  );

  return (
    <div
      className={classNames(
        "-mb-4 -mt-[2px] mr-8 flex items-center",
        styles.creditTypeSelectContainer,
      )}
    >
      <Subtitle
        level={4}
        className={classNames("mr-8", styles.pricingUnitLabel)}
      >
        Pricing unit:
      </Subtitle>
      <Select
        className={styles.creditTypeSelect}
        onChange={(creditTypeId) => {
          setSelectedCreditTypeId(creditTypeId);
          const selectedCreditType = creditTypes.find(
            (ct) => ct.id === creditTypeId,
          );
          if (selectedCreditType) {
            onChange(selectedCreditType);
          }
        }}
        value={selectedCreditTypeId}
        placeholder="Choose pricing unit"
        options={creditTypes.map((ct, idx) => ({
          label: displayCreditTypeName(ct),
          value: ct.id,
        }))}
      />
    </div>
  );
};

type CreditHeaderProps = {
  sortedCreditTypes: CreditType[];
  selectedCreditType: CreditType;
  setSelectedCreditType: (creditType: CreditType) => void;
  currentLedger: Ledger;
};
const CreditHeader: React.FC<CreditHeaderProps> = ({
  sortedCreditTypes,
  selectedCreditType,
  setSelectedCreditType,
  currentLedger,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-between",
        styles.creditsHeader,
      )}
    >
      <div className={classNames("flex", styles.left)}>
        <CreditMetric
          isPrimary={true}
          label="Available credits"
          amount={currentLedger?.availableBalance ?? new Decimal(0)}
          creditType={selectedCreditType ?? USD_CREDIT_TYPE}
        />
        <CreditMetric
          isPrimary={false}
          label="Total consumed"
          amount={currentLedger?.consumed ?? new Decimal(0)}
          creditType={selectedCreditType ?? USD_CREDIT_TYPE}
        />
        <CreditMetric
          isPrimary={false}
          label="Total expired"
          amount={currentLedger?.expired ?? new Decimal(0)}
          creditType={selectedCreditType ?? USD_CREDIT_TYPE}
        />
        <CreditMetric
          isPrimary={false}
          label="Total Issued"
          amount={currentLedger?.totalGranted ?? new Decimal(0)}
          creditType={selectedCreditType ?? USD_CREDIT_TYPE}
        />
      </div>
      <div className={classNames("flex items-center", styles.right)}>
        {sortedCreditTypes.length > 1 && (
          <CreditTypeSelect
            creditTypes={sortedCreditTypes}
            selectedCreditType={selectedCreditType ?? USD_CREDIT_TYPE}
            onChange={setSelectedCreditType}
          />
        )}
      </div>
    </div>
  );
};

export default CreditHeader;
